import React, { Component } from 'react'
import {ProductConsumer} from "../context/context";
import styled from 'styled-components';

import nord from "../norge/nord.png"
import mid from "../norge/mid.png"
import east from "../norge/east.png"
import west from "../norge/west.png"


export default class SearchCompany extends Component {

  render() {

    return (

        <ProductConsumer>
        {value => {
          const { nameSearch, companyBy, companyZip, handleSearchCompanyZipInput,handleSearchCompanyLocationInput, handleSearchCompanyNameInput,   handleResetCompanySearche, handleNord, handleMid, handleEast, handleWest } = value;
  return (
      <StyledDiv>
      <div className='d-flex flex-row-reverse'>
               <button type="button" className="btn btn-outline-primary" onClick={handleResetCompanySearche}>Nullstill søk</button>
 
      </div>

      <div className="row mt">
        <div className="col input-effect  px-0">
        	<input className="effect-20" type="text" placeholder="" value={nameSearch} onChange={(handleSearchCompanyNameInput)}/>
            <label>søk etter frakter</label>
            <span className="focus-border">
            	<i></i>
            </span>
            </div>
            </div>


          <div className="row mt-3">
        <div className="col input-effect px-0">
        	<input className="effect-20" type="text" placeholder="" value={companyBy} onChange={(handleSearchCompanyLocationInput)}/>
            <label>Søk by</label>

            <span className="focus-border">
            	<i></i>
            </span>
        </div>
        </div>


        <div className="row mt-3">
        <div className="col input-effect  px-0">
        	<input className="effect-20" type="text" placeholder="" value={companyZip} onChange={(handleSearchCompanyZipInput)}/>
            <label>søk postnummer</label>
            <span className="focus-border">
            	<i></i>
            </span>
            </div>
            </div>

            <div className="row mt-3">
            <div className="col mapWrapper">

            <img src={nord} className="nord" onClick={handleNord} data-bs-toggle="tooltip" data-bs-placement="left" title="Nord-Norge"/>
            <img src={mid} className="mid" onClick={handleMid} data-bs-toggle="tooltip" data-bs-placement="left" title="Midt-Norge"/>
            <img src={east} className="east" onClick={handleEast} data-bs-toggle="tooltip" data-bs-placement="left" title="Øst"/>
            <img src={west} className="west" onClick={handleWest} data-bs-toggle="tooltip" data-bs-placement="left" title="Vest"/>



            </div>
            </div>
      
      </StyledDiv>
      );
        }}
      </ProductConsumer>
    )
  }
}


const StyledDiv = styled.div`


*:focus{outline: none;}

/* necessary to give position: relative to parent. */
input[type="text"]{font: 15px/24px 'Muli', sans-serif; color: #333; width: 100%; box-sizing: border-box; letter-spacing: 1px;}

*:focus{outline: none;}

.col{float: left; width: 80.33%; margin: 5px 3%; position: relative;} /* necessary to give position: relative to parent. */
input[type="text"]{font: 15px/24px "Lato", Arial, sans-serif; color: #333; width: 100%; box-sizing: border-box; letter-spacing: 1px;}

.effect-20
{border: 1px solid #ccc; padding: 7px 14px; transition: 0.4s; background: transparent;}

.effect-20 ~ .focus-border:before,
.effect-20 ~ .focus-border:after{content: ""; position: absolute; top: 0; left: 0; width: 0; height: 2px; background-color: #4caf50; transition: 0.3s;}
.effect-20 ~ .focus-border:after{top: auto; bottom: 0; left: auto; right: 0;}
.effect-20 ~ .focus-border i:before,
.effect-20 ~ .focus-border i:after{content: ""; position: absolute; top: 0; left: 0; width: 2px; height: 0; background-color: #4caf50; transition: 0.4s;}
.effect-20 ~ .focus-border i:after{left: auto; right: 0; top: auto; bottom: 0;}
.effect-20:focus ~ .focus-border:before,
.effect-20:focus ~ .focus-border:after,
.has-content.effect-20 ~ .focus-border:before,
.has-content.effect-20 ~ .focus-border:after{width: 100%; transition: 0.3s;}
.effect-20:focus ~ .focus-border i:before,
.effect-20:focus ~ .focus-border i:after,
.has-content.effect-20 ~ .focus-border i:before,
.has-content.effect-20 ~ .focus-border i:after{height: 100%; transition: 0.4s;}
.effect-20 ~ label{position: absolute; left: 14px; width: 100%; top: 10px; color: #aaa; transition: 0.3s; z-index: -1; letter-spacing: 0.5px;white-space:nowrap;}
.effect-20:focus ~ label, .has-content.effect-20 ~ label{top: -18px; left: 0; font-size: 12px; color: #4caf50; transition: 0.3s; white-space:nowrap;}



.nord{
position: absolute;
top:2;
left:60px;
cursor: pointer;
}
.nord:hover{
  filter: brightness(0) saturate(100%) invert(27%) sepia(78%) saturate(1701%) hue-rotate(90deg) brightness(90%) contrast(107%);
}

.mid{
  position: absolute;
  top:57px;
  left:30px;
  cursor: pointer;
}
.mid:hover{
  filter: brightness(0) saturate(100%) invert(27%) sepia(78%) saturate(1701%) hue-rotate(90deg) brightness(90%) contrast(107%);
}

.east{
  position: absolute;
  top:116px;
  left:12px;
  cursor: pointer;

}
.east:hover{
  filter: brightness(0) saturate(100%) invert(27%) sepia(78%) saturate(1701%) hue-rotate(90deg) brightness(90%) contrast(107%);
}

.west{
  position: absolute;
  top:108px;
  left:0px;
  cursor: pointer;

}
.west:hover{
  filter: brightness(0) saturate(100%) invert(27%) sepia(78%) saturate(1701%) hue-rotate(90deg) brightness(90%) contrast(107%);
}

.mapWrapper{
height:180px;
}
`
