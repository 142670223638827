import React, { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Message from "../components/Message";
import {connect} from "react-redux";
import ScrollToBottom from '../components/ScrollToBottom';




class messages extends Component {
    state = {
        messages:null,
        loading:true,
        email:null,
        body:"",
    }

    
   
        async componentDidMount() {
            const email = this.props.match.params.email;
            this.setState({email})

        axios.get(`/messages/${email}`)
        .then(res=>{
            let messagesStorted = res.data.sort((a,b)=>a.createdAt > b.createdAt ? 1 : -1)
            this.setState({
                messages:messagesStorted,
                loading:false
            });
        })
        .catch(err=>console.log(err))
        

  }



  handleChange = (event)=>{
    this.setState({
        body:event.target.value
    })
}


handleSubmit = ( event )=>{
    event.preventDefault();

     const chatObject = {
      body: this.state.body,
      email: this.state.email
  }
  this.setState({
      messages:[...this.state.messages, chatObject]  
    })

    axios.post(`/message`, chatObject)
    .then(()=>{
        this.setState({body:null})
        event.target.reset();
    })
    

    .catch((error)=>{
        console.log(error+" some chat error:/")
    })
}
    render() {

        const {  user: {
            authenticated,
            credentials: {userEmail }
        }

    } = this.props;
        return (
          
            <StyledDiv>
              <ScrollToBottom/>
            <div className="container">
            <div className="d-flex align-items-start flex-column contentContainer">
                <div className="col1 my-4">
                    <div className="">   
                    <h3>Meldinger med {this.state.email}</h3>
                </div>
            </div>
            <div className="col2 my-1">

            {this.state.loading ? (<p>loading...</p>) : (this.state.messages.map((message)=>{return <Message key={message.messageId} message={message}/>}))}
            </div>

<div className="formContainer" >
<div className="innerContainer">
    <form onSubmit={this.handleSubmit} >
        <div className="form-group my-3">
        <label htmlFor="jobTitle"></label>
            <textarea 
                cols="80" 
                rows="4"
                type="text" 
                name="body"
                className="form-control" 
                id="body" 
                aria-describedby="bodyHelp" 
                placeholder="Melding" 
                onChange={this.handleChange}/>
        </div>
        <button type="submit" className="btn btn-success" > Send</button>
    </form>
    </div>
</div>
</div>
</div>

        </StyledDiv>
        )
    }
}

const mapStateToProps = state =>({
    user:state.user,
})
export default connect(mapStateToProps)(messages)



const StyledDiv = styled.div`
.form-control:hover{
    background-color: #F2F3F5;
}
`