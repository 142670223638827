import { SET_JOBS, SET_VURDERINGER, LOADING_DATA, DELETE_JOB, POST_JOB, SET_JOB, POST_VURDERING, DELETE_VURDERING, CHANGEJOBSTATUS, POST_COMPANY, SET_COMPANIES, SET_COMPANY, DELETE_COMPANY } from "../types";


const initialState = {
    jobs:[],
    vurderinger:[],
    job:[],
    loading: false
};

export default function(state = initialState, action){
    switch(action.type){
        case LOADING_DATA:
            return {
                ...state,
                loading:true
            };
        case SET_JOBS:
            return{
                ...state,
                jobs:action.payload,
                loading:false
            };
        case SET_VURDERINGER:
            return {
                ...state,
                vurderinger:action.payload,
                loading:false
            }

        case DELETE_VURDERING:
            let indexVurderinger = state.vurderinger.findIndex(vurdering => vurdering.vurderingId === action.payload);
           state.vurderinger.splice(indexVurderinger, 1);
           return{
               ...state
           };
        case SET_JOB:
            return{
                ...state,
                job: action.payload
            };
        case DELETE_JOB:
           let index = state.jobs.findIndex(job => job.jobId === action.payload);
           state.jobs.splice(index, 1);
           return{
               ...state
           };
        case POST_JOB:
            return {
                ...state,
                jobs:[
                    action.payload,
                    ...state.jobs
                ]
            };

        case POST_VURDERING:
            return{
                ...state,
                vurderinger:[
                    action.payload,
                    ...state.vurderinger
                ]
            };

        case CHANGEJOBSTATUS:
            return{
                ...state,
                jobs:[
                    action.payload,
                    ...state.jobs
                ]
            };

        case POST_COMPANY:
            return{
                ...state,
                companies:[
                    action.payload,
                    ...state.companies
                ]
            }
        
            case SET_COMPANIES:
                return{
                    ...state,
                    companies:action.payload,
                    loading:false
                };

            case SET_COMPANY:
                return{
                    ...state,
                    company:action.payload
                };

            case DELETE_COMPANY:
                let indexCompanies = state.companies.findIndex(company => company.companyId === action.payload);
                state.companies.splice(indexCompanies, 1);
                return{
                    ...state
                };

         default: 
         return state;
    }
}
