import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`

    position: absolute;
    color:black;
    font-size: 16px;
    font-weight: bold;
    top: 50%;
    left: 50%;
    width: 23px;
    height: 23px;
    background-color: #FFB20C;
    border: 2px solid black;
    text-align: center;
    border-radius: 100%;
    cursor:pointer;
`;

const Marker = ({ text, onClick }) => (
  <Wrapper
    alt={text}
    onClick={onClick}
  >
  <p>{text}</p>
  </Wrapper>
);

Marker.defaultProps = {
  onClick: null,
};



export default Marker;