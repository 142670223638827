import React from "react";

class ScrollToBottom extends React.Component {

  componentDidUpdate() {
    let intViewportHeight = window.innerHeight; 
    window.scrollTo(0,intViewportHeight+10000)

  }

  render() {
    return null;
  }
}
export default ScrollToBottom;
