import React, { Component } from 'react'
//import PropTypes from "prop-types"
import styled from 'styled-components';
import {Link} from "react-router-dom";

import {connect} from 'react-redux';
import { signupUser } from '../redux/actions/userActions';



export class signup extends Component {


    state={
        email:'',
        password:'',
        confirmPassword:'',
        firstName:'',
        lastName:'',
        errors:{}
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.UI.errors) {
          this.setState({ errors: nextProps.UI.errors });
        }
      }

handleChange = (event) =>{
    this.setState({
        [event.target.name]:event.target.value
    })
}
handleSubmit = (event)=>{
    event.preventDefault();
    this.setState({
        loading: true
        
    });
    const newUserData = {
        email:this.state.email,
        password:this.state.password,
        confirmPassword:this.state.confirmPassword,
        firstName:this.state.firstName,
        lastName:this.state.lastName


    }
  this.props.signupUser(newUserData, this.props.history);

}


    render() {
const {errors } = this.state;
const { UI:{loading} } = this.props;

        return (
            <StyledDiv>
            <div className="container">
            <div className="row justify-content-center">
            <div className="col col-sm-10 col-xs-10 col-lg-4 text-center">
            <h1>Registrer</h1>

                <form  noValidate onSubmit={this.handleSubmit}>
                {/* EMAIL */}
                <div className="form-group my-3">
                    <label htmlFor="InputEmail">Epost</label>
                    <input 
                    type="email" 
                    name="email"
                    className="form-control" 
                    id="email" 
                    aria-describedby="emailHelp" 
                    placeholder="Din Epost" 
                    value={this.state.email} 
                    onChange={this.handleChange}/>
                    
                    <small id="emailHelp" className="form-text  text-danger"> {errors.email } </small>
                </div>

                {/* Password */}
                <div className="form-group my-3">
                    <label htmlFor="InputPassword">Passord</label>
                    <input 
                    type="password" 
                    name="password"
                    className="form-control" 
                    id="password" 
                    placeholder="Passord"  
                    value={this.state.password} 
                    onChange={this.handleChange}/>
                    <small id="passwordHelp" className="form-text  text-danger"> {errors.password ? errors.password : null} </small>
                </div>

                                {/* confirmPassword */}

                <div className="form-group my-3">
                    <label htmlFor="InputPassword">Bekreft passord</label>
                    <input 
                    type="Password" 
                    name="confirmPassword"
                    className="form-control" 
                    id="confirmPassword" 
                    placeholder="Bekreft passord"  
                    value={this.state.confirmPassword} 
                    onChange={this.handleChange}/>
                    <small id="confirmPasswordHelp" className="form-text  text-danger"> {errors.confirmPassword ? errors.confirmPassword : null} </small>
                </div>

                {/* firstName */}
                <div className="form-group my-3">
                    <label htmlFor="firstName">Fornavn</label>
                    <input 
                    type="text" 
                    name="firstName"
                    className="form-control" 
                    id="firstName" 
                    placeholder="Fornavn"  
                    value={this.state.firstName} 
                    onChange={this.handleChange}/>
                    <small id="firstNameHelp" className="form-text  text-danger"> {errors.firstName ? errors.firstName : null} </small>
                </div>

                {/* lastName */}
                <div className="form-group my-3">
                    <label htmlFor="lastName">Etternavn</label>
                    <input 
                    type="text" 
                    name="lastName"
                    className="form-control" 
                    id="lastName" 
                    placeholder="Etternavn"  
                    value={this.state.lastName} 
                    onChange={this.handleChange}/>
                    <small id="lastNameHelp" className="form-text  text-danger"> {errors.lastName ? errors.lastName : null} </small>
                </div>



                <div>
                <small id="" className="form-text text-danger"> {errors.general} </small>
                </div>
                <button 
                type="submit" 
                disabled={loading}
                className="btn btn-primary mt-3 btnSubmitForm"> {loading? <div className="spinner-border" role="status"></div> : "Registrer"}
                </button>
                </form>

                <br/>
                <small>Har du allerede en konto? <Link to="/login">Logg inn</Link></small>

            </div>
            </div>
                
            </div>
            </StyledDiv>
        )
    }
}

//signup.propTypes = {
//    classes: PropTypes.object.isRequired
//}

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI
  });
  

export default connect(
    mapStateToProps,
    {signupUser}
  )((signup));


const StyledDiv = styled.div`
    margin-top:80px;


.form-check-label{
    margin-left:6px;
}
.spinner-border{
    height:20px;
    width: 20px;
}

`