import React, { Component, Fragment } from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux"
import PostJob from "./PostJob";
import styled from 'styled-components';
import logo from "../logo.png"
import noface from "../noFace.png"
import chatting from "../chatting.png";
import {ProductConsumer} from "../context/context";
import { markNotificationsRead } from "../redux/actions/userActions";
import AllMessages from './AllMessages';
import finn from "../finn.png"
import beta from "../beta.jpg"





 class Navbar extends Component {

state={
  messagesOpen:false
} 

  handleRead = ()=>{
    let unreadNotificationsIds = this.props.user.notifications.filter(not => !not.read).map(not => not.notificationId);
    this.props.markNotificationsRead(unreadNotificationsIds);
    this.setState({messagesOpen:true})
  }

    render() {        
      return(
          <ProductConsumer>
          {value=>{
            const {handleOpenMessages, getAllCompanies }=value;
        const { user, authenticated} = this.props;
        
        let notifications = user.notifications; 
        let notificationsIcon;
        if(notifications && notifications.length > 0){
          (notifications.filter(not => not.read === false).length > 0)
         ? (notificationsIcon = ( <><div className="col  float-end messageWrapper" onClick={this.handleRead}>
                                  <div className="notifications">{notifications.filter(not=>not.read === false).length}</div>
                                  <img className="chatting" src={chatting} alt="chatlogo" /> 
                                  <span  className="meldingerSpan">Meldinger </span>
                                  </div>
                                  <AllMessages messagesOpen={this.state.messagesOpen}/>
                                 </> )) 
         : (notificationsIcon = (<div className="col text-nowrap float-end " >
                      <img className="chatting" src={chatting} onClick={handleOpenMessages} alt="chatlogo"/> 
                      <span onClick={handleOpenMessages} className="meldingerSpan">Meldinger </span>
                      </div>))
        } else{
          notificationsIcon =( <div className="col text-nowrap float-end " >
                      <img className="chatting" src={chatting} onClick={handleOpenMessages} alt="chatlogo"/> 
                      <span onClick={handleOpenMessages}  className="meldingerSpan">Meldinger </span>
                      </div>)
        }

      

        
        return (       
     <>
     {authenticated ? (
         <Fragment>
         <StyleWrapper2>
         
         <div className="container-fluid">
    <div className="row justify-content-between flex-nowrap">
        <div className="col text-start logothing">
        <img src={beta} alt="betaicon" className='betaIcon'/>
        <a href="/">
            <img src={logo} alt="logo" className="logo "/>
            </a>
            
            <span className='d-flex flex-nowrap'>
            <Link to="/om">
             <img style={{height:"15px"}} src="https://img.icons8.com/glyph-neue/64/000000/info.png" alt="infoLogo"/>
             </Link>
             </span>
            </div>
   
        <div className='col align-middle'><Link to="/transporter" className="align-middle"><img  src={finn} style={{ marginTop:"10px"}} className="finnlogo" alt="findicon" onClick={getAllCompanies} data-bs-toggle="tooltip" data-bs-placement="left" title="Transportfirma"/></Link>
        </div>
        <div className='col'> <PostJob/>
     
        </div>
    <div className="col">
    <div className="d-flex justify-content-end">

                   {notificationsIcon}

        <div className="col text-nowrap text-end my-auto">
            <Link to="/profile">
            <img src={noface} alt="userprofileimage" className="noFaceImage"/>
            <span className="link">{user.credentials.firstName}</span>
            </Link>
        </div>    
    </div>
</div>
</div>
</div>
</StyleWrapper2>
         </Fragment>
     )
     :
     (
         <Fragment>
         <StyleWrapper>
         <div className="container-fluid">
         <div className="row justify-content-between flex-nowrap">
         <div className="col text-start logothing px-0">
         <img src={beta} alt="betaicon" className='betaIcon'/>

         <a href="/">
             <img src={logo} alt="logo" className="logo"/>
             </a>
             <span>
             <Link to="/om">
             <img style={{height:"15px"}} src="https://img.icons8.com/glyph-neue/64/000000/info.png" alt="infoLogo"/>
             </Link>
             </span>
         </div>
         <div className='col align-middle'><Link to="/transporter" className="align-middle"><img  src={finn} style={{ marginTop:"10px"}} className="finnlogo" onClick={getAllCompanies} alt="findicon" data-bs-toggle="tooltip" data-bs-placement="left" title="Transportfirma"/></Link>
</div>

         <div className="col text-end my-auto px-0">
         <div className="d-flex justify-content-end">
                <Link to="/login" className="link">Logg inn</Link>
                <Link to="/signup" className="link">Registrer</Link>
        </div>
         </div>
         </div>
         </div>
         </StyleWrapper>
         </Fragment>
     )
     }

            </>
 
        )
      }}
          </ProductConsumer>
          )
    }
}



const mapStateToProps = state =>({
    authenticated:state.user.authenticated,
    user:state.user
})

export default connect(mapStateToProps, {markNotificationsRead}) (Navbar);


const StyleWrapper = styled.div`

position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  height: 64px;
  text-align: center;
  z-index: 200;
  background: #FFB20C;

.finnlogo{
  height:40px;
  @media (max-width: 600px) {
      height:35px;
  } 
  @media (max-width: 360px) {
      height:25px;
  }
}

  .finnlogo:hover{
    transition: all .2s ease-in-out; 
    transform: scale(1.1); 
  }

  .logo{
    height:60px;
    @media (max-width: 600px) {
      height:45px;
  }  
  @media (max-width: 360px) {
      height:35px;
  } 
  }
  .logo:hover{
    transition: all .2s ease-in-out; 
transform: scale(1.1); 
  }
.link{
    text-decoration: none;
    font-size: 16px;
    font-weight:bold;
    color:black;
    margin:10px;
    white-space: nowrap;
    @media (max-width: 600px) {
      font-size: 13px;
  }  
  @media (max-width: 360px) {
      font-size: 11px;
  } 
}

.insideRow{
    height: 100%;
    @media (max-width: 700px) {
 white-space: nowrap;
    direction: rtl;  }  
}
.colInside{
 white-space: nowrap;
    direction: rtl;   
}

.betaIcon{
  position:absolute;
  transform: rotate(90deg) ;
  width:50px;
  top:0;
  right:0;
}

`
const StyleWrapper2 = styled.div`

position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  height: 64px;
  text-align: center;
  z-index: 200;
  background: #FFB20C;

  .finnlogo{
  height:40px;
  @media (max-width: 600px) {
      height:35px;
  }  
  @media (max-width: 360px) {
      height:20px;
  } 
}
  .finnlogo:hover{
    transition: all .2s ease-in-out; 
    transform: scale(1.1); 
  }

  .logo{
    height:52px;
    margin-top: 3px;
    @media (max-width: 600px) {
      height:42px;
      @media (max-width: 360px) {
      height:30px;
  } 
  }  
  }
  .logo:hover{
    transition: all .1s ease-in-out; 
transform: scale(1.1); 
  }
  .noFaceImage{
    margin-top: 3px;
    height: 35px;
    @media (max-width: 600px) {
      height:30px;
  } 
  @media (max-width: 360px) {
      height:25px;
  } 
  }


  .chatting{
    height: 35px;
    cursor: pointer;
    margin:10px;
    @media (max-width: 600px) {
      height:30px;
  } 
  }
  .chatting:hover{
    transition: all .1s ease-in-out; 
transform: scale(1.1); 
  }

  .noFaceImage:hover{
    transition: all .1s ease-in-out; 
transform: scale(1.1); 
  }


  .link{
    text-decoration: none;
    font-size: 12px;
    margin-left:10px;
    font-weight:bold;
    color:black;
    @media (max-width: 600px) {
      font-size: 13px;
  }  
  @media (max-width: 360px) {
      font-size: 11px;
  } 
    @media (max-width: 700px) {
 display:none ;
  }  
}
.meldingerSpan{
  cursor: pointer;
  text-decoration: none;
    font-size: 12px;
    margin-left:10px;
    font-weight:bold;
    color:black;
  @media (max-width: 700px) {
 display:none ;
  }  
}

a:hover, a:visited, a:link, a:active {

text-decoration: none!important;

-webkit-box-shadow: none!important;
box-shadow: none!important;
}

.messageWrapper{
  position: relative;
}
.notifications{
  position: absolute;
  top:4px;
  left:65px;
  background-color: #FA3E3E;
  border-radius: 50%;
  height: 19px;
  width: 18px;
  color:white;
  font-weight: 600;
  font-size: 12px;
}

.logothing{
  white-space: nowrap;
}


.betaIcon{
  position:absolute;
  transform: rotate(90deg) ;
  width:50px;
  top:0;
  right:0;
}
`

