import React, { Component } from 'react';
import styled from 'styled-components';
import Company from "./company";
import {Link} from "react-router-dom";
import { ProductConsumer } from "../context/context";
import SearchCompany from '../components/SearchCompany';





export default class transporter extends Component {
  render() {
    
    return (
      <ProductConsumer>
      {value => {
        const { filteredCompanies, loadingCompanies } = value;
        return (
      <StyledDiv>
      <div className='container'>
        <div className='row'>
            <div className='col-sm-3'>
            <div className='d-flex align-items-start mt-4'>
            <SearchCompany/>
            </div>
            </div>
            <div className='col-sm-9 mt-4'>
            <h4>Transportfirma</h4>
            <Link to="/registerCompany" className='d-flex flex-row-reverse' data-bs-toggle="tooltip" data-bs-placement="left" title="Registrer tronsportfirma">Registrer firma</Link>

            {loadingCompanies ?  (<p>loading...</p>) : (filteredCompanies.map((company)=>{ return <Company key={company.companyId} company={company}/>})) }




            </div>
        </div>


      </div>
      </StyledDiv>
      );
        }}
      </ProductConsumer>

    )
  }
}


const StyledDiv = styled.div`




`
