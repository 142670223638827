import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from "react-bootstrap";
import {registerCompany, clearErrors} from "../redux/actions/dataActions";
import { Redirect } from "react-router-dom";
import {Link} from "react-router-dom";





class registerCompanyPage extends Component{
    state = {
    companyName: '',
    companyAbout: '',
    companyWww: '',
    companyTlf:'',
    companyEmail:'',
    companyLocation:'',
    companyZip:'',
    companyInsurance:'',
    fraktFra1:'',
    fraktTil1:'',
    fraktFra2:'a',
    fraktTil2:'b',
    redirect: false,
    errors:{}
    
    };




    componentWillReceiveProps(nextProps){
        if(nextProps.UI.errors){
            this.setState({
                errors:nextProps.UI.errors
            })
        }
    }


    handleChange = (event)=>{
        this.setState({
            [event.target.id]:event.target.value
        })
    }

    handleRedirect = () =>{
          this.setState({redirect: true })   
    }

    handleSubmit = (event)=>{
        event.preventDefault();
        this.props.registerCompany({
            companyName: this.state.companyName,
            companyAbout: this.state.companyAbout,
            companyWww: this.state.companyWww,
            companyTlf:this.state.companyTlf,
            companyEmail:this.state.companyEmail,
            companyLocation:this.state.companyLocation,
            companyZip:this.state.companyZip,
            companyInsurance:this.state.companyInsurance,
            fraktFra1:this.state.fraktFra1,
            fraktTil1:this.state.fraktTil1,
            fraktFra2:this.state.fraktFra2,
            fraktTil2:this.state.fraktTil2,
        });

        this.handleRedirect();
    }

    render(){
      
        const {errors}=this.state;
        const {UI:{loading}} = this.props;

        if (this.state.redirect) {
            return <Redirect to={"/"} />
          }

        return(
            <StyledDiv>
            <Fragment>


            <div className='container'>
                    <Modal.Title> Registrer selskap</Modal.Title>
                    <Modal.Body>
                   
<form onSubmit={this.handleSubmit}>

    <div className="form-group my-3">
    <label htmlFor="companyName">Navn på selskap / Frakter</label>
    <input 
    type="text" 
    name="companyName"
    className="form-control" 
    id="companyName" 
    aria-describedby="companyNameHelp" 
    placeholder="Navn på selskap / Frakter" 
    onChange={this.handleChange}
    />
    <small id="companyNameHelp" className="form-text  text-danger"> {errors.body ? errors.body : null} </small>     
    </div>



        <div className="form-group my-3">
        <label htmlFor="companyAbout">Om frakter</label>
        <textarea 
        cols="40" 
        rows="4"
        type="text" 
        name="companyAbout"
        className="form-control" 
        id="companyAbout" 
        aria-describedby="companyAboutHelp" 
        placeholder="Om frakter" 
        onChange={this.handleChange}/>
        <small id="companyAboutHelp" className="form-text  text-danger"> {errors.body ? errors.body : null} </small>
        </div>

        <div className="form-group my-3">
        <p>Tilbyr forsikring?</p>
        <label htmlFor="companyInsurance">ja</label>
        <input
        className='radio'
        type="radio" 
        name="companyInsurance"
        value="ja"
        id="companyInsurance" 
        aria-describedby="companyInsuranceHelp" 
        placeholder="Om frakter" 
        onChange={this.handleChange}
        />
        <label htmlFor="companyInsurance">nei</label>
        <input 
        className='radio'
        type="radio" 
        name="companyInsurance"
        value="nei"
        id="companyInsurance" 
        aria-describedby="companyInsuranceHelp" 
        placeholder="Om frakter" 
        onChange={this.handleChange}
        />
       
        <small id="companyInsurance" className="form-text  text-danger"> {errors.body ? errors.body : null} </small>
        </div>

        <div className="form-group my-3">
            <div className="row">                 
            <label htmlFor="companyWww">Nettside</label>
                <div className="col-8">       
                    <input 
                    type="text"
                    name="companyWww"
                    className="form-control paymentSlider" 
                    id="companyWww" 
                    aria-describedby="companyWwwHelp" 
                    placeholder="Nettside" 
                    onChange={this.handleChange}/>
                </div>
            </div>
            </div>

            <div className="form-group my-3">
            <div className="row">                 
            <label htmlFor="companyTlf">Tlf</label>
                <div className="col-8">       
                    <input 
                    type="text"
                    name="companyTlf"
                    className="form-control paymentSlider" 
                    id="companyTlf" 
                    aria-describedby="companyTlfHelp" 
                    placeholder="Tlf nummer til kontaktperson" 
                    onChange={this.handleChange}/>
                </div>
            </div>
            </div>

            <div className="form-group my-3">
            <div className="row">                 
            <label htmlFor="companyEmail">Epost</label>
                <div className="col-8">       
                    <input 
                    type="text"
                    name="companyEmail"
                    className="form-control paymentSlider" 
                    id="companyEmail" 
                    aria-describedby="companyEmailHelp" 
                    placeholder="Epost til kontaktperson" 
                    onChange={this.handleChange}/>
                </div>
            </div>
            </div>

            <div className="form-group my-3">
            <div className="row">                 
            <label htmlFor="companyLocation">Frakters lokasjon</label>
                <div className="col-8">       
                    <input 
                    type="text"
                    name="companyLocation"
                    className="form-control paymentSlider" 
                    id="companyLocation" 
                    aria-describedby="companyLocationHelp" 
                    placeholder="Hvor holder du til?" 
                    onChange={this.handleChange}/>
                </div>
            </div>

            </div>
            <div className="form-group my-3">
            <div className="row">                 
            <label htmlFor="companyZip">Frakters postnummer</label>
                <div className="col-8">       
                    <input 
                    type="text"
                    name="companyZip"
                    className="form-control paymentSlider" 
                    id="companyZip" 
                    aria-describedby="companyZipHelp" 
                    placeholder="Hvor holder du til?" 
                    onChange={this.handleChange}/>
                </div>
            </div>
            </div>

            <div className="row">
            <div className="col-4 col-xs-8">
                    <div className="form-group ">
                    <label htmlFor="fraktFra1">Jeg kjører mellom</label>
                    <input 
                    type="text" 
                    name="fraktFra1"
                    className="form-control" 
                    id="fraktFra1" 
                    aria-describedby="fraktFra1Help" 
                    placeholder="by jeg kjører fra" 
                    onChange={this.handleChange}
                    />
                    <small id="fraktFra1Help" className="form-text  text-danger"> {errors.body ? errors.body : null} </small>     
                    </div>
            </div>
            <div className='col-1 d-flex align-items-center'><p className='align-bottom mb-0'>og</p></div>

            <div className="col-4">
                                <div className="form-group">
                                <label htmlFor="fraktTil1"></label>
                                <input 
                                type="text" 
                                name="fraktTil1"
                                className="form-control" 
                                id="fraktTil1" 
                                aria-describedby="fraktTil1Help" 
                                placeholder="by jeg kjører til" 
                                onChange={this.handleChange}
                                />
                                <small id="fraktTil1Help" className="form-text  text-danger"> {errors.body ? errors.body : null} </small>     
                                </div>
                </div>
            </div>

            <div className="row mt-4">
            <div className="col-4 col-sx-8">
                    <div className="form-group ">
                    <label htmlFor="fraktFra2">Jeg kjører også mellom</label>
                    <input 
                    type="text" 
                    name="fraktFra2"
                    className="form-control" 
                    id="fraktFra2" 
                    aria-describedby="fraktFra2Help" 
                    placeholder="by jeg kjører fra" 
                    onChange={this.handleChange}
                    />
                    <small id="fraktFra2Help" className="form-text  text-danger"> {errors.body ? errors.body : null} </small>     
                    </div>
            </div>
            <div className='col-1 d-flex align-items-center'><p className='align-bottom mb-0'>og</p></div>

            <div className="col-4">
                                <div className="form-group">
                                <label htmlFor="fraktTil2"></label>
                                <input 
                                type="text" 
                                name="fraktTil2"
                                className="form-control" 
                                id="fraktTil2" 
                                aria-describedby="fraktTil2Help" 
                                placeholder="by jeg kjører til" 
                                onChange={this.handleChange}
                                />
                                <small id="fraktTil2Help" className="form-text  text-danger"> {errors.body ? errors.body : null} </small>     
                                </div>
                </div>
            </div>

<div className="row justify-content-between mt-4">
    <div className="col text-center">              <Link to="/transporter">
 <Button variant="secondary" onClick={this.handleClose}>Tilbake</Button></Link></div>
    <div className="col text-center"><button type="submit" className="btn btn-success" disabled={loading}>{loading? <div className="spinner-border" role="status" disabled={loading}></div> : "Registrer"}</button>
</div>
</div>
<p className="text-center my-2"><small>Last opp logo og cover bilde etter av frakter er registrert!</small></p>
</form>
                    </Modal.Body>
                    </div>
            </Fragment>
            </StyledDiv>
        )
    }


}

const mapStateToProps = (state)=>({
    UI: state.UI
})



export default connect(mapStateToProps,{registerCompany, clearErrors}) (registerCompanyPage)





const StyledDiv = styled.div`

.addButton{
    margin-top: 17px;;
    cursor: pointer;
    font-size: 32px;
    color:black;
}

.addButton:hover{
    transition: all .2s ease-in-out; 
transform: scale(1.1); 
}

.modal-content{
    width: 90%;
}

.form p {
    margin-bottom: 5px;
}

.modal-dialog{
    max-width:80%;
}

.radio{
    margin-right: 10px;
}

`
