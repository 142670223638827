import React, { Component } from 'react';
import styled from 'styled-components';
import {ProductConsumer} from "../context/context";
import dayjs from 'dayjs';

export default class AllMessages extends Component {


    render() {
     
        return (
            <ProductConsumer>
              {value => {
                const {  messagesOpen,messages, loading } = value;
        
                const defaultUserImage = <img className="defaultProfileImg" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" alt="userprofileimg"/>

        return (
            <>
            {
                (messagesOpen || this.props.messagesOpen) ? (
            <StyleWrapperShow>
            <>
            {!loading ?
                (messages.map((item)=>(  
                (<div className="wrapper" key={item.messageId}>
                
                {item.recipientEmail === item.ownEmail ? (
                    <span><a href={`/messages/${item.userEmail}`}> 
                { item.userImage ? <img className="defaultProfileImg" src={item.userImage} alt="wtf"/>  : defaultUserImage }
                {item.userEmail}</a>
                </span>
                ) : (
                    <span><a href={`/messages/${item.recipientEmail}`}> 
                { item.userImage ? defaultUserImage  : defaultUserImage }
                {item.recipientEmail}</a>
                </span>
                )}

             <span className="createdAt"><small className="text-muted"> {dayjs(item.createdAt).fromNow() }</small></span> 
                     <div className="messageBody">
                      <small>{item.body} </small>  
                    </div>
                     </div>)
            ))):
            <span>loading...</span>
            }
            </>
            </StyleWrapperShow>
                ) : (
            <StyleWrapper>
            
            </StyleWrapper>
                )
            }
            </>
        )
    }}
      </ProductConsumer>
    );
    }
}

const StyleWrapperShow = styled.div`


    background-color: white;
    border: 1px solid gray;
    border-radius: 2%;
    width: 400px;
    min-height: 300px;
    position: fixed;
    top:65px;
    right:20px;
    z-index: 2000;

    .defaultProfileImg{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin:10px;
    
}
.createdAt{

}
.wrapper{
    position: relative;
}

.wrapper:hover{
    background-color: #F2F3F5;
    border-radius: 6%;

}

.messageBody{
position:absolute;
bottom:3px;
left:73px;
max-width: 200px; 
white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
}


`

const StyleWrapper = styled.div`

display: none;

`