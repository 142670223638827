import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";
import {getJob} from "../redux/actions/dataActions";


class SingleJob extends Component{
    state={
        open:false
    }

    handleOpen = ()=>{
        this.setState({open:true})
        this.props.getJob(this.props.jobId);
    }

    handleClose = ()=>{
        this.setState({open:false})
    }

    render(){
     
        const {job:{ 
            jobId,
            userEmail,
     } } = this.props;
   
    
   return (
       <StyledDiv>
        <Fragment>
     <div>
         <h1>Hello from jodialog</h1>
         <h1>{userEmail}</h1>
         <h1>{jobId}</h1>


     </div>

        </Fragment>
       </StyledDiv>


    )
    }
 
}


const mapStateToProps = state =>({
    job: state.data.job,
    UI:state.UI
})

const mapActionsToProps = {
    getJob
}

export default connect(mapStateToProps, mapActionsToProps)(SingleJob)



const StyledDiv = styled.div`
margin-top: 80px;
.arrowRight{
    margin-left: 20px;
    margin-right:20px;
    color: #424242;

    
}

.spanFromTo{
    color: #424242;
    font-size: smaller;
}

.defaultProfileImg{
    width: 45px;
    border-radius: 50%;
}

.card-text{
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical; 
}
.defaultPackageImage{
    object-fit: cover;
    width: 100%;
    display: block;
    margin:auto;
    padding:12px;
    filter: grayscale(80%);
}

.link{
    text-decoration: none;
}
`
