// original working with fing cors=)
//<GoogleMapReact
//        bootstrapURLKeys={{ key:process.env.REACT_APP_API_KEY }}
//        defaultCenter={coordinates}
//        center={center}
//        defaultZoom={5}
//        zoom={zoomLvl}
//        margin={[50,50,50,50]}
//        options={''}
//        onChange={''}
//        onChildClick={''}
//        yesIWantToUseGoogleMapApiInternals
//        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
//
//        >
//
//                <Marker 
//                text="A"
//                lat={startLat}
//                lng={startLng}
//                />
//                <Marker
//                text="B"
//                lat={endLat}
//                lng={endLng}
//    />
//
//</GoogleMapReact> 

import React, { Component } from 'react';
import GoogleMapReact from "google-map-react";
import styled from 'styled-components';
import Marker from "./Marker";
import {ProductConsumer} from "../context/context";

class Map extends Component {



    render() {
const coordinates = {lat:60, lng:18};
return (
    <ProductConsumer>
      {value => {
        const {  
        startLat,
        startLng,
        endLat,
        endLng,
        center,
        zoomLvl,
        dLat,
        dLng

        } = value;




return (
    <StyledDiv> 

    <GoogleMapReact
        bootstrapURLKeys={{ key:process.env.REACT_APP_API_KEY }}
        defaultCenter={coordinates}
        center={center}
        defaultZoom={5}
        zoom={zoomLvl}
        margin={[50,50,50,50]}
        options={''}
        onChange={''}
        onChildClick={''}
        yesIWantToUseGoogleMapApiInternals

        >

        <Marker
          text="loading..."
          lat={dLat}
          lng={dLng}
        />

                <Marker 
                text="A"
                lat={startLat}
                lng={startLng}
                />
                <Marker
                text="B"
                lat={endLat}
                lng={endLng}
    />

</GoogleMapReact> 
    </StyledDiv>
)
}}
      </ProductConsumer>
        )

}

}



export default Map



const StyledDiv = styled.div`  

    position: -webkit-sticky;
    position:sticky;
    top: 80px;
    height:91vh;
    width:100%;
      @media (max-width: 700px) {
    height: 40vh;
  }


  


`
