import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";
import {deleteCompany} from "../redux/actions/dataActions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal } from "react-bootstrap";



 

 class DeleteCompany extends Component {
     state={
         open:false
     }

     handleOpen = () =>{
         this.setState({open:true})
     }
     handleClose = () =>{
        this.setState({open:false})
    }
    deleteCompany = () =>{
        this.props.deleteCompany(this.props.companyId);
        this.setState({open:false})
    }
    render() {
        return (
            <StyledDiv>
            <Fragment>
         <FontAwesomeIcon icon={faTrashAlt} onClick={this.handleOpen}  className="deleteButton" data-bs-toggle="tooltip" data-bs-placement="left" title="Slett frakter profil"/>


<Modal className="modalOverlay d-flex align-items-center justify-content-center" show={this.state.open} >
<Modal.Header closeButton onClick={this.handleClose}>
            <Modal.Title> Slett frakter profil </Modal.Title>
          </Modal.Header>
          <Modal.Body>
                    <div className="">
                    <p>Du er i ferd med å slette profilen for godt.</p>
<button className="btn btn-danger" onClick={this.deleteCompany}>Slett</button>
                    </div>

          </Modal.Body>
        </Modal>

         </Fragment>
            </StyledDiv>
        )
    }
}


export default connect(null, {deleteCompany})(DeleteCompany)


const StyledDiv = styled.div`

.deleteButton{
    position:absolute;
    top:2%;
    right:2%;
    color: red;
    cursor: pointer;
    z-index: 500;
}
.deleteButton:hover{
 transition: all .2s ease-in-out; 
transform: scale(1.1); 
}

`
