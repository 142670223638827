import React, { Component } from 'react'
import axios from 'axios';
import {getCompany} from "../redux/actions/dataActions";
import {connect} from "react-redux";
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import DeleteCompany from '../components/DeleteCompany';
import { Link } from "react-router-dom"




import Company from "./company";



export  class SingleCompany extends Component {

    state = {
        company:null,
        loading:true

    }
    componentDidMount(){
        const companyId = this.props.match.params.companyId;


        this.props.getCompany(companyId);
        axios.get(`/company/${companyId}`)
        .then(res=>{
            console.log(res.data)
            this.setState({
                company:res.data,
                loading:false
            });
        })
        .catch(err=>console.log(err))
    }



    
  render() {

    const {  
    user: {
        authenticated,
        credentials: {email, }
    },
    
    } = this.props;


    
         const changeCompanyLogo = this.state.loading ? null :  ( authenticated && email === this.state.company.userEmail ? (
        <>
                       <input type="file" id="imageLogo" onChange={(event)=>this.handleImageChange(event, this.state.company.companyId)} hidden="hidden" />
                       <FontAwesomeIcon icon={faCamera} onClick={this.handleEditlogo} className="iconButton" data-bs-toggle="tooltip" data-bs-placement="left" title="Bytt/legg til logo" />
       </>
    ) : (null)
    )
  
  
  
    const changeCompanyCover = this.state.loading ? null : ( authenticated && email === this.state.company.userEmail ? (
      <>
                     <input type="file" id="imageInput" onChange={(event)=>this.handleCoverChange(event, this.state.company.companyId)} hidden="hidden" />
                     <FontAwesomeIcon icon={faCamera} onClick={this.handleEditPicture} className="iconButtonCover" data-bs-toggle="tooltip" data-bs-placement="left" title="Bytt/legg til cover bilde" />
     </>
  ) : (null)
    )
  
  
  const deleteButton = this.state.loading ? null : ( authenticated && email === this.state.company.userEmail ? (
    <DeleteCompany companyId={this.state.company.companyId}/>
  ) 
  : 
  null
  )
   


 const company = this.state.loading ?  (<p>loading...</p>) : (
     <div className='container'>
    <div className="w-100 mainWrapper">
          <div className="card my-3">
          {deleteButton}

          {changeCompanyCover}
        <img className="card-img imageCover" src={this.state.company.companyCover} alt="coverimage"/>
        <div className="card-img-overlay">
          <img className="imageLogo" src={this.state.company.companyLogo} alt="Logo"/>
          {changeCompanyLogo}
        </div>
        <div className="card-body">
        <div className="row">
            <div className='col'>
                 <h4 className="card-title" >{this.state.company.companyName}</h4>
                 <p className="card-text">{this.state.company.companyAbout}</p>

            </div>
            <div className='col'>
                <h5>Faste ruter</h5>
                <div className='d-flex'>
                    <span>{this.state.company.fraktFra1}</span>
                    <span className="arrowRight"> <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-arrow-left-right mx-3 arrows" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/></svg></span>
                    <span>{this.state.company.fraktTil1}</span>
                </div>
                <div className='d-flex'>
                    <span>{this.state.company.fraktFra2}</span>
                    <span className="arrowRight"> <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-arrow-left-right mx-3 arrows" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/></svg></span>
                    <span>{this.state.company.fraktTil2}</span>
                </div>

            </div>
        </div>

        </div>
        <div className="card-footer text-muted d-flex flex-wrap justify-content-between bg-transparent border-top-0">

        <div className=""><a href={`https://${this.state.company.companyWww}`} target="_blank" rel="noopener noreferrer">{this.state.company.companyWww}</a></div>
        <div className="">{this.state.company.companyTlf}</div>
        <div className=""> {this.state.company.companyLocation}</div>
        <div className=''>Epost: {this.state.company.companyEmail}</div>

           
        </div>
      </div>
      </div>
      </div>
) 
    return (
        <StyledDiv>

{company}





        </StyledDiv>
    )
  }
}


const mapStateToProps = state =>({
    data:state.data,
    user:state.user,

})



export default connect(mapStateToProps, {getCompany}) (SingleCompany)


const StyledDiv = styled.div`


.card-img {
    border-radius:10px;
    height:20vh;
    width:auto;
    object-fit:  contain;

  }

  .imageLogo{
      position:absolute;
      width: 112px;
      height:112px;
      left:0;
      bottom:0;
      object-fit:  contain;

  }

.card-img-overlay{
    position:relative ;
}

.imageCover{
object-fit:  cover;
width:100%;
height:150px;
}

.arrows{
    border:1px solid black;
    border-radius:50%;
    padding:3px;
}

.iconButton{
    position: absolute;
    left:2%;
    bottom:9%;
    color:lightgray;
    filter: drop-shadow(0px 0px 5px black);
    cursor: pointer;

}

.iconButtonCover{
  position: absolute;
    left:1%;
    top:6%;
    color:lightgray;
    filter: drop-shadow(0px 0px 5px black);
    cursor: pointer;
}



`
