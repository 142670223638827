import React, { Component } from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {connect} from "react-redux";
import DeleteJob from "./DeleteJob";
import ChangeJobStatus from './ChangeJobStatus';
//import JobDialog from "./JobDialog";
//import Comments from "./Comments";
import { ProductConsumer } from "../context/context";
import mapRoute from "../map.png";
import chatting from "../chatting.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import {uploadJobImage } from "../redux/actions/dataActions";

class Job extends Component {

    state={
        imageOpen:false,
       
    }

    handleImageChange = (event, jobId) => {
        const image = event.target.files[0];
        const formData = new FormData();
        formData.append('image', image, image.name);
        this.props.uploadJobImage(formData, jobId);
    };


    handleEditPicture = () =>{
        const fileInput = document.getElementById('jobImageInput');
        fileInput.click();
    }

    handleImageFullSize=()=>{
        this.setState({imageOpen:!this.state.imageOpen})
    }

    render() {
        dayjs.extend(relativeTime)
        const {  job : {
        jobId,
        userImage,
        jobTitle,
        userEmail,
        userFirstName,
        body,
        jobImage,
        createdAt,
        payment,
        cityFrom,
        zipFrom,
        cityTo,
        zipTo,
        

        
    },
    user: {
        authenticated,
        credentials: {email }
    },
    job
} = this.props;

    const defaultUserImage = <img className="defaultProfileImg" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" alt="userprofileimg"/>;
    const defaultPackageImage = <img className="defaultPackageImage" src="https://firebasestorage.googleapis.com/v0/b/minfrakt.appspot.com/o/default-job.png?alt=media&token=241f532c-c43f-4ee5-a308-603b9d827898" alt="packageimg"/>

   const deleteButton = authenticated && email === userEmail ? (
      <DeleteJob jobId={jobId}/>
   ) 
 : 
 null

    const ChangeJobStatusButton = authenticated && email === userEmail ? (
        <ChangeJobStatus jobId={jobId}/>
    )
    :
    null

 const changeJobImage = authenticated && email === userEmail ? (
     <>
                    <input type="file" id="jobImageInput" onChange={(event)=>this.handleImageChange(event,jobId)} hidden="hidden" />
                    <FontAwesomeIcon icon={faCamera} onClick={this.handleEditPicture} className="iconButton" data-bs-toggle="tooltip" data-bs-placement="left" title="Bytt/legg av det du ønsker å sende" />
    </>
 ) : (null)
 
   

 return (
    <ProductConsumer>
      {value => {
        const { handleClickedJob } = value;
        return (
            <>
            <StyledDiv> 
                <div className= "card mb-3 fullBoddy">
                <div>{ChangeJobStatusButton}</div>

                <div className="row g-0" >
                <div className="row p-2">
                    <div className="col-2 col-md-2 col-xs-2 col-lg-1 alsoProfileImageWrapper" >
                    { userImage ? <img className="defaultProfileImg" src={userImage} alt="wtf"/>  : defaultUserImage }
                    </div>
                    <div className="col-8 col-md-8 col-xs-8 px-4">
                    <Link className="link" to={`/users/${userEmail}`}> 
                    <div className="wrapLink">
                        { userFirstName ? userFirstName : userEmail }
                    </div>
                    </Link>
                        <div className="row" >
                            <div className="col">
                                <p className="card-text"><small className="text-muted">{dayjs(createdAt).fromNow()} </small></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-2 col-md-2 col-sm-2 text-end px-0">
                        <p className="payment">Betaling <strong>{parseInt(payment,10)}</strong> kr</p>
                    </div>                
                </div>
                <div className="row">
                    <div className="col-2 col-md-3 col-xs-3 contentWrapper">
                   <div className="imageControl">
                    {jobImage ? <img className="defaultPackageImage" src={jobImage} alt="wtf" onClick={this.handleImageFullSize}/> : defaultPackageImage  } 
                   </div>
                   {changeJobImage}
                    </div>
                    <div className="col-8 col-md-7 col-xs-7 pe-0 textCss"> {deleteButton}
                    <div className="card-body px-0">                       
                        <h5 className="card-title">{jobTitle} </h5>
                        <span className="spanFromTo">{cityFrom.charAt(0).toUpperCase()+cityFrom.slice(1)+", "+ zipFrom}</span>
                        <span className="arrowRight"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/></svg></span>
                        <span className="spanFromTo">{cityTo.charAt(0).toUpperCase()+cityTo.slice(1)+", "+ zipTo} </span>
                        <p className="bodyText"> {body} </p>
                        <div>
                        </div>
                    </div>  
                    </div>
                    <div className="col-2 col-md-2 col-xs-1 my-auto ">
                        <img src={mapRoute} alt="map" className="map" onClick={()=>handleClickedJob(job.jobId)}/>
                        {
                            authenticated  ? 
                            userEmail === email ? (null) 
                            :
                            (<Link to={`/messages/${userEmail}`}>
                            <img src={chatting} className="chatting" user={this.props.user} alt="chatlogo"/></Link>)
                            :  
                            (<Link to="/login"><img src={chatting} className="chatting" user={this.props.user} alt="chatlogo"/></Link>)
                        }
                    </div>
                    </div>
                </div>
                </div>
            </StyledDiv>
            {
                        this.state.imageOpen ? 
                        <StyledImageOpen onClick={this.handleImageFullSize}>
                            <div className="imageModalWrapper">
                            <span className="closeModal" onClick={this.handleImageFullSize}>X</span>
                             <img className="imageFull" src={jobImage} alt="wtf" />
                            </div>
                        </StyledImageOpen>
                        :
                        <StyledImageClosed>
                        </StyledImageClosed>
                    }
          </>  
            );
        }}
      </ProductConsumer>
        )
    }
}

const mapStateToProps = state =>({
    user:state.user,
    data:state.data
})

const mapActionsToProps = {
    uploadJobImage
}
export default connect(mapStateToProps, mapActionsToProps)(Job)

const StyledDiv = styled.div`

.card-body{
    z-index: 20;
    background-color:white;
    padding-top:5px;
    padding-bottom:5px;
    padding-left: 25px;
    @media (max-width: 900px) {
        margin-right: 0;
        padding-right: 0;
  }
    @media (max-width: 700px) {
    margin: 0px;
    padding: 0px;
    padding-left: 5px;
  }
  @media (max-width: 470px) {
    padding-left: 15px;
  }
  @media (max-width: 440px) {
    padding-left: 20px;
  }
}

.arrowRight{
    margin-left: 20px;
    margin-right:20px;
    color: #424242;  
}

.spanFromTo{
    color: #424242;
    font-size: smaller;
}

.defaultProfileImg{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    
}

.card-text{
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 5; /* number of lines to show */
   -webkit-box-orient: vertical; 
}

.bodyText{
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 6; /* number of lines to show */
   -webkit-box-orient: vertical; 
}

.imageControl{
    cursor: pointer;
    width:110%;

    overflow:hidden;

    @media (max-width: 1420px) {
        width:110%;
  }
  @media (max-width: 1100px) {
    width:118%;
  }
    @media (max-width: 900px) {
        width:120%;
  }
    @media (max-width: 750px) {
        width:120%;
  }
  @media (max-width: 550px) {
        width:140%;
  }
  @media (max-width: 475px) {
        width:140%;
  }
  @media (max-width: 450px) {
        width:150%;
  }
  @media (max-width: 400px) {
        width:200%;
  }
  @media (max-width: 380px) {
        width:220%;
  }
  @media (max-width: 340px) {
        width:250%;
  }
}
.defaultPackageImage{
    object-fit: contain;
    height: 100%;
    max-height: 140px;
    display: block;
    margin:auto;
    padding:12px;
    padding-left: 5px;
    filter: grayscale(50%);
    @media (max-width: 1420px) {
    height: 120px;
  }
    @media (max-width: 1100px) {
    max-height: 110px;
  }
    @media (max-width: 900px) {
    max-height: 100px;
  }
    @media (max-width: 700px) {
    margin: 0px;
    padding: 0px;
    max-height: 75px;
  }
}

.wrapLink{
        @media(max-width: 850px){
        white-space: nowrap; 
        max-width: 140px; 
        overflow: hidden;
        text-overflow: ellipsis; 
    }
}

.link{
    text-decoration: none;

}

.mapContainer{
    display: none;  

}

.textCss{
    @media (max-width: 400px) {
    padding-left: 40px;
  }  
}

.fullBoddy:hover{
    border-color: gray;
}
.fullBoddy:active{
  border-color: green;
  transform: translateY(4px);
}

.contentWrapper{
    padding-left: 5px;
    

    @media (max-width: 700px) {
    padding-left: 4px;
  }  
}

.payment{
    white-space: nowrap;
    direction: rtl;
    @media (max-width: 700px) {
        font-size: 12px;
  }  
}

 .map{
    height: 40px;
    cursor: pointer;
    margin:10px;
}

.chatting{
    height: 40px;
    cursor: pointer;
    margin:10px;
    filter: invert(65%) sepia(62%) saturate(624%) hue-rotate(354deg) brightness(100%) contrast(105%);

}

.iconButton{
    position: absolute;
    left:2%;
    bottom:8%;
    color:lightgray;
    filter: drop-shadow(0px 0px 5px black);
}
.iconButton:hover{
    cursor: pointer;
}
`

const StyledImageOpen = styled.div`
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;


.imageModalWrapper{
    position: relative;
  background-color: #fefefe;
  margin-left: 20%;
  padding: 20px;
  width: 50%;
   
}
.imageFull{
    width: 100%;

}
.closeModal{
color: white;
position: absolute;
top:20px;
right: 25px;
  font-size: 22px;
  cursor: pointer;
  font-weight: 700;
  z-index: 99999;


}
.closeModal:hover{
cursor: pointer;
transition: all .1s ease-in-out; 
transform: scale(1.1); 
}

`

const StyledImageClosed = styled.div`
display: none;
`


