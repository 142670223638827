import React, { Component } from 'react';
import styled from 'styled-components';
import dayjs from "dayjs";
import {connect} from "react-redux";
import relativeTime from 'dayjs/plugin/relativeTime';
import {FaStar} from "react-icons/fa";
import DeleteVuredring from './DeleteVuredring';


class Vurderinger extends Component {



 render(){
    dayjs.extend(relativeTime)
    const {  vurdering : {
    createdAt,
    vurderingGjortAv,
    vurdering,
    rating,
    vurderingGjortAvUserImage,
    vurderingId
},
user: {
    authenticated,
    credentials: {email, }
}
} = this.props;


const deleteButtonVurdering = authenticated && email === vurderingGjortAv ? (
    <DeleteVuredring vurderingId={vurderingId}/>
 ) 
: 
null

    const defaultUserImage = <img className="defaultProfileImg" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" alt="userprofileimg"/>

    const colors = {
        orange: "#FFBB5A",
        gray:"a9a9a9"
    }

    const styles = {
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
    }
    
    const ratingInt =  parseInt(rating)

    const stars = ratingInt !== null  ? (Array(ratingInt).fill(0)) : (Array(0).fill(0));

         return (
            <StyledDiv>
                <div className="card p-2">
                <div className="text-nowrap">
                <div className="row">
                <div className="col-4 col-sm-2">
                { vurderingGjortAvUserImage ? <img className="defaultProfileImg" src={vurderingGjortAvUserImage} alt="userImage"/>  : defaultUserImage }
                </div>
                <div className="col-8 col-sm-10">
                    <div className="row">
                        <div className="col">
                            <div style={styles.stars}>
                                {stars.map((_, index)=>{
                                    return(
                                        <FaStar
                                            key={index}
                                            size={22}
                                            style={{marginRight:5}}
                                            color={colors.orange}
                                        />
                                        )
                                })}
                            </div>
                        </div>  
                        <div className="col">
                            <span className="text-right"><small className="text-muted">{dayjs(createdAt).fromNow()} </small></span>
                        </div>
                            
                    </div>  
                    <div className="row">
                        <div className="col">
                          <a href={`/users/${vurderingGjortAv}`}><span className="vurderingGjortAv">{vurderingGjortAv}</span> </a>
                            
                </div>
                </div>
                </div>
                </div>
                <div className="row">
                    <div className="col-10 col-sm-10">
                        <div className="vurdering">{vurdering}</div> 
                    </div>
                    <div className="col-2 col-sm-2">
                        {deleteButtonVurdering}
                    </div>
                </div>                
            </div>
            </div>
            </StyledDiv>
        )
 }
 }

 const mapStateToProps = state =>({
    user:state.user,
    data:state.data
})

const mapActionsToProps = {
    
}
export default connect(mapStateToProps, mapActionsToProps)(Vurderinger)

const StyledDiv = styled.div`

.defaultProfileImg{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;

}
`
