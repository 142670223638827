import { 
    SET_JOBS,
    SET_VURDERINGER, 
    LOADING_DATA, 
    DELETE_JOB, 
    LOADING_UI, 
    CLEAR_ERRORS, 
    SET_ERRORS,
    POST_JOB,
    POST_VURDERING,
    DELETE_VURDERING,
    SET_JOB,
    STOP_LOADING_UI,
    CHANGEJOBSTATUS,
    POST_COMPANY,
    SET_COMPANIES,
    SET_COMPANY,
    DELETE_COMPANY
 } from '../types';
import axios from 'axios';

//GET ALL JOBS
export const getJobs = () => (dispatch) =>{
dispatch({type:LOADING_DATA});
axios.get('/jobs')
.then(res=>{
    dispatch({
        type: SET_JOBS,
        payload:res.data
    })
})
.catch(err =>{
    dispatch({
        type:SET_JOBS,
        payload:[]
    })
})
}
//GET ONE JOB
export const getJob = (jobId) => (dispatch)=>{
    dispatch({type:LOADING_UI});
    axios.get(`/job/${jobId}`)
    .then(res=>{
        dispatch({
            type:SET_JOB,
            payload:res.data
        });
        dispatch({type: STOP_LOADING_UI})
    })
    .catch(err=>console.log(err))
}

//changeJobStatus  sett jobb som utført

export const changeJobStatus = (jobId) => (dispatch) =>{
    axios.post(`/job/${jobId}`)
    .then(()=>{
        dispatch({
            type: CHANGEJOBSTATUS,
            payload:jobId
        });
    })
    .catch(err=>console.log(err))
}


//Post job
export const postJob = (newJob) => (dispatch) => {
    dispatch({type:LOADING_UI});
    axios.post('/job', newJob)
    .then(res=>{
        dispatch({
            type: POST_JOB,
            payload: res.data
        });
        dispatch({type: CLEAR_ERRORS});
    })
    .catch(err=>{
        dispatch({
            type:SET_ERRORS,
            payload:err.response.data
        })
    })
};

//Post vurdering 
export const postVurdering = (nyVurdering) => (dispatch) =>{
    const userName= window.location.href.split('/').reverse()[0];
    dispatch({type:LOADING_UI});
    axios.post(`/user/${userName}/vurdering`, nyVurdering)
    .then(res=>{
        dispatch({
            type: POST_VURDERING,
            payload: res.data
        });
        dispatch({type: CLEAR_ERRORS});
    })
    .catch(err=>{
        dispatch({
            type:SET_ERRORS,
            payload:err.response.data
        })
    })
}

//Delete Vurdering
export const deleteVurdering = (vurderingId) => (dispatch) =>{
    axios.delete(`/vurdering/${vurderingId}`)
    .then(()=>{
        dispatch({type:DELETE_VURDERING, payload:vurderingId})
    })
    .catch(err =>console.log(err));
}

//upload job image 
export const uploadJobImage = (formData, jobId) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
        .post(`/job/${jobId}/jobImage`, formData)
        .then(()=>{
            dispatch(getJobs());
      })
      .catch((err) => console.log(err +"hell "));
    };


//Delete job
export const deleteJob = (jobId) => (dispatch) =>{
    axios.delete(`/job/${jobId}`)
    .then(()=>{
        dispatch({type:DELETE_JOB, payload:jobId})
    })
    .catch(err =>console.log(err));
}

export const getUserData = (userEmail) =>(dispatch)=>{
    dispatch({ type: LOADING_DATA })
    axios.get(`/user/${userEmail}`)
    .then(res=>{
        dispatch({
            type: SET_JOBS,
            payload: res.data.jobs
        })
        dispatch({
            type: SET_VURDERINGER,
            payload: res.data.vurderinger
        })
    })
    .catch(err=>{
        dispatch({
            type:SET_JOBS,
            payload: null
        });
    });
}
//GET ALL COMPANIES
export const getAllCompanies = () => (dispatch) =>{
    dispatch({type:LOADING_DATA});
    axios.get('/companies')
    .then(res=>{
        dispatch({
            type: SET_COMPANIES,
            payload:res.data
        })
    })
    .catch(err =>{
        dispatch({
            type:SET_COMPANIES,
            payload:[]
        })
    })
    }

    //register companie
export const registerCompany = (newCompanie) => (dispatch) => {
    dispatch({type:LOADING_UI});
    axios.post('/registerCompany', newCompanie)
    .then(res=>{
        dispatch({
            type: POST_COMPANY,
            payload: res.data
        });
        dispatch({type: CLEAR_ERRORS});
    })
    .catch(err=>{
        dispatch({
            type:SET_ERRORS,
            payload:err.response.data
        })
    })
};


//GET ONE COMPANY
export const getCompany = (companyId) => (dispatch)=>{
    dispatch({type:LOADING_UI});
    axios.get(`/company/${companyId}`)
    .then(res=>{
        dispatch({
            type:SET_COMPANY,
            payload:res.data
        });
        dispatch({type: STOP_LOADING_UI})
    })
    .catch(err=>console.log(err))
}


//upload company image 
export const uploadCompanyLogo = (formData,companyId) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
      .post(`/company/${companyId}/companyLogo`, formData)
      .then(()=>{
            dispatch(getAllCompanies());
    
      })
      
      .catch((err) => console.log(err +"hell "));
    };

    //upload company cover 
export const uploadCompanyCover = (formData,companyId) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
      .post(`/company/${companyId}/companyCover`, formData)
      .then(()=>{
            dispatch(getAllCompanies());
    
      })
      
      .catch((err) => console.log(err +"hell "));
    };

export const clearErrors = () =>(dispatch)=>{
    dispatch({type:CLEAR_ERRORS});
}


//Delete Company  (company profile)
export const deleteCompany = (companyId) => (dispatch) =>{
    axios.delete(`/company/${companyId}`)
    .then(()=>{
        dispatch({type:DELETE_COMPANY, payload:companyId})
    })
    .catch(err =>console.log(err));
}

