import React, { Component } from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";

import { Modal, Button } from "react-bootstrap";
import {postVurdering, clearErrors} from "../redux/actions/dataActions";
import {FaStar} from "react-icons/fa";




class NyVurdering extends Component{
    state = {
        open:false,
        vurdering:'',
        rating:0,
        errors:{},

        hoverValue:undefined,
    
    };

    

    componentWillReceiveProps(nextProps){
        if(nextProps.UI.errors){
            this.setState({
                errors:nextProps.UI.errors
            })
        }
        if (!nextProps.UI.errors && !nextProps.UI.loading) {
            this.setState({ vurdering: '', rating:0, open: false, errors: {} });
          }
    }

            //stars related
            handleClickOnStars =(value)=>{
                this.setState({rating:value})
            }
            handleMouseOver = (value)=>{
                this.setState({hoverValue:value})
            }
            handleMouseLeave = ()=>{
                this.setState({hoverValue:undefined})
            }


    handleOpen =()=>{
        this.setState({open:true})
    }
    handleClose =()=>{
        this.props.clearErrors()
        this.setState({open:false}) 
    }


    handleChange = (event)=>{
        this.setState({
            [event.target.id]:event.target.value
        })
    }

    handleRedirect = () =>{
        window.location.reload(false);
    }

    handleSubmit = (event)=>{
        event.preventDefault();
        this.props.postVurdering({
        vurdering:this.state.vurdering,
        rating:this.state.rating
        });
    }

    render(){

        const colors = {
            orange: "#FFBB5A",
            gray:"a9a9a9"
        }
        const styles = {
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
        }
        const stars =  (Array(5).fill(0));
        const {errors}=this.state;
        const {UI:{loading}} = this.props;

        return(
            <StyledDiv>
            <div className="container my-3">

            <button onClick={this.handleOpen}  className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="left" title="Gi vurdering til bruker">Gi vurdering</button>



                    <Modal className="modal-fullscreen-md-down mt-5" show={this.state.open} >
                    <Modal.Header closeButton onClick={this.handleClose}>
                    </Modal.Header>
                    <Modal.Body>
                   
<form onSubmit={this.handleSubmit} className="rating">
    <div style={styles.stars}>
                {stars.map((_, index)=>{
                    return(
                        <FaStar
                        id="rating"
                        name="rating"
                        error={errors.vurdering ? true : false}
                            key={index}
                            size={22}
                            style={{marginRight:5}}
                            color={(this.state.hoverValue || this.state.rating) > index ? colors.orange : colors.gray }
                            onClick={()=>this.handleClickOnStars(index + 1)}
                            onMouseOver={()=>this.handleMouseOver(index + 1)}
                            onMouseLeave={this.handleMouseLeave}
                        />
                    )
                })}
                <div id="jobTitleHelp" className="form-text  text-danger"> {errors.vurdering ? errors.vurdering : null} </div>     

            </div>



   {/* <input type="file" id="imageInput" onChange={this.handleImageChange} hidden="hidden" />
    <FontAwesomeIcon icon={faCamera} onClick={this.handleEditPicture} className="iconButton" data-bs-toggle="tooltip" data-bs-placement="left" title="Legg til bilde"/>
*/}

        
        <div className="form-group my-3">
        <label htmlFor="body">Vurdering av bruker</label>
        <textarea 
        cols="40" 
        rows="4"
        type="text" 
        name="vurdering"
        className="form-control" 
        id="vurdering" 
        aria-describedby="vurderingHelp" 
        placeholder="vurdering" 
        onChange={this.handleChange}/>
        <small id="vurderingHelp" className="form-text  text-danger"> {errors.vurdering ? errors.vurdering : null} </small>
        </div>




<div className="row justify-content-between mt-4">
    <div className="col text-center"> <Button variant="secondary" onClick={this.handleClose}>Lukk</Button></div>
    <div className="col text-center"><button type="submit" className="btn btn-success" disabled={loading}>{loading? <div className="spinner-border" role="status" disabled={loading}></div> : "Legg ut"}</button>
</div>
</div>
</form>
                    </Modal.Body>
                    </Modal>
            </div>
            </StyledDiv>
        )
    }


}

const mapStateToProps = (state)=>({
    UI: state.UI
})



export default connect(mapStateToProps,{postVurdering, clearErrors}) (NyVurdering)





const StyledDiv = styled.div`

.container{
    text-align: center;

}
.btn-primary{
}

.btn-primary:hover{
    transition: all .2s ease-in-out; 
transform: scale(1.1); 
}

.modal-content{
    width: 90%;
}

.form p {
    margin-bottom: 5px;
}

.modal-dialog{
    max-width:80%;
}




`
