import React from 'react'

export default function om() {
    return (
        <div className="container">
        <div className="row mt-4">
            <div className="col-sm-12">
                <h2>Om FrakteTing.no</h2>
                <p>FrakteTing.no er en side laget for å gjøre det lett å finne noen som kan frakte dine ting.</p>
                <p>Si noe om hva tingen veier og hvor stor tingen er, foreslå en pris du ønsker å betale.</p>
                <p>FrakteTing.no har ikke noe med selve frakten av tingen å gjøre!</p>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-12">
                <h2>Kontakt</h2>
                <p>Ta kontakt per epost: frakteting@gmail.com</p>
                
            </div>
        </div>

        </div>
       
    )
}
