import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from "react-bootstrap";
import {postJob, clearErrors} from "../redux/actions/dataActions";
import { Redirect } from "react-router-dom";




class PostJob extends Component{
    state = {
        open:false,
        jobTitle:'',
        body:'',
        jobImage:'',
        payment:0,
        streetNameFrom:'',
        cityFrom:'',
        zipFrom:'',
        streetNameTo:'',
        cityTo:'',
        zipTo:'',
        redirect: false,
        errors:{}
    };

    componentWillReceiveProps(nextProps){
        if(nextProps.UI.errors){
            this.setState({
                errors:nextProps.UI.errors
            })
        }
    }

    handleOpen =()=>{
        this.setState({open:true})
    }
    handleClose =()=>{
        this.props.clearErrors();
        this.setState({open:false})
    }


    handleChange = (event)=>{
        this.setState({
            [event.target.id]:event.target.value
        })
    }

    handleRedirect = () =>{
          this.setState({redirect: true })   
    }

    handleSubmit = (event)=>{
        event.preventDefault();
        this.props.postJob({
        jobTitle:this.state.jobTitle,
        body:this.state.body,
        jobImage:this.state.jobImage,
        payment:this.state.payment,
        streetNameFrom:this.state.streetNameFrom,
        cityFrom:this.state.cityFrom,
        zipFrom:this.state.zipFrom,
        streetNameTo:this.state.streetNameTo,
        cityTo:this.state.cityTo,
        zipTo:this.state.zipTo
        });

        this.handleRedirect();
    }

    render(){
      
        const {errors}=this.state;
        const {UI:{loading}} = this.props;

        if (this.state.redirect) {
            return <Redirect to={"/"} />
          }

        return(
            <StyledDiv>
            <Fragment>

            <FontAwesomeIcon icon={faPlus} onClick={this.handleOpen}  className="addButton" data-bs-toggle="tooltip" data-bs-placement="left" title="Legg ut ny fraktejobb"/>



                    <Modal className="modal-fullscreen-md-down mt-5" show={this.state.open} >
                    <Modal.Header closeButton onClick={this.handleClose}>
                    <Modal.Title> Hva ønsker du å sende?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                   
<form onSubmit={this.handleSubmit}>

    <div className="form-group my-3">
    <label htmlFor="jobTitle">Tittel</label>
    <input 
    type="text" 
    name="jobTitle"
    className="form-control" 
    id="jobTitle" 
    aria-describedby="jobTitleHelp" 
    placeholder="Tittel" 
    onChange={this.handleChange}
    />
    <small id="jobTitleHelp" className="form-text  text-danger"> {errors.body ? errors.body : null} </small>     
    </div>

   {/* <input type="file" id="imageInput" onChange={this.handleImageChange} hidden="hidden" />
    <FontAwesomeIcon icon={faCamera} onClick={this.handleEditPicture} className="iconButton" data-bs-toggle="tooltip" data-bs-placement="left" title="Legg til bilde"/>
*/}

        
        <div className="form-group my-3">
        <label htmlFor="body">Beskrivelse</label>
        <textarea 
        cols="40" 
        rows="4"
        type="text" 
        name="body"
        className="form-control" 
        id="body" 
        aria-describedby="bodyHelp" 
        placeholder="Beskrivelse" 
        onChange={this.handleChange}/>
        <small id="bodyHelp" className="form-text  text-danger"> {errors.body ? errors.body : null} </small>
        </div>

            <div className="form-group my-3">
            <div className="row">                 
            <label htmlFor="payment">Betaling</label>
                <div className="col-8">       
                    <input 
                    type="range"
                    min="0"
                    max="20000"
                    value={this.state.payment}
                    name="payment"
                    className="form-control paymentSlider" 
                    id="payment" 
                    aria-describedby="paymentHelp" 
                    placeholder="Hvor mye ønsker du å betale" 
                    onChange={this.handleChange}/>
                </div>
                <div className="col-4"> 
                    <input 
                    type="number"
                    value={this.state.payment}
                    name="payment"
                    className="form-control paymentBox" 
                    id="payment" 
                    onChange={this.handleChange}/>
                </div>
            </div>
     

            </div>


            <div className="row">
            <p className="mb-1">Jeg ønsker å sende <strong>fra</strong></p>
            <div className="col-5">
                <div className="form-group ">
                <label htmlFor="streetNameFrom">Adresse</label>
                <input 
                type="text" 
                name="streetNameFrom"
                className="form-control paymentSlider" 
                id="streetNameFrom" 
                aria-describedby="jstreetNameFromHelp" 
                placeholder="Adresse" 
                onChange={this.handleChange}
                />
                </div>

            </div>
            <div className="col-4">
                    <div className="form-group ">
                    <label htmlFor="cityFrom">By</label>
                    <input 
                    type="text" 
                    name="cityFrom"
                    className="form-control" 
                    id="cityFrom" 
                    aria-describedby="cityFromHelp" 
                    placeholder="Adresse" 
                    onChange={this.handleChange}
                    />
                    <small id="cityFromHelp" className="form-text  text-danger"> {errors.body ? errors.body : null} </small>     
                    </div>

            </div>
            <div className="col-3">
                        <div className="form-group ">
                        <label htmlFor="zipFrom">Postnummer</label>
                        <input 
                        type="text" 
                        name="zipFrom"
                        className="form-control" 
                        id="zipFrom" 
                        aria-describedby="zipFromHelp" 
                        placeholder="Postnummer" 
                        onChange={this.handleChange}
                        />
                        <small id="zipFromHelp" className="form-text  text-danger"> {errors.body ? errors.body : null} </small>     
                        </div>

            </div>
            </div>
            <div className="row mt-5">
            <p className="mb-1">Jeg ønsker å sende <strong>til</strong></p>

                <div className="col-5">
                            <div className="form-group">
                            <label htmlFor="streetNameTo">Adresse</label>
                            <input 
                            type="text" 
                            name="streetNameTo"
                            className="form-control" 
                            id="streetNameTo" 
                            aria-describedby="streetNameToHelp" 
                            placeholder="Adresse" 
                            onChange={this.handleChange}
                            />
                            </div>
                </div>
                <div className="col-4">
                                <div className="form-group">
                                <label htmlFor="cityTo">By</label>
                                <input 
                                type="text" 
                                name="cityTo"
                                className="form-control" 
                                id="cityTo" 
                                aria-describedby="cityToHelp" 
                                placeholder="Adresse" 
                                onChange={this.handleChange}
                                />
                                <small id="cityToHelp" className="form-text  text-danger"> {errors.body ? errors.body : null} </small>     
                                </div>
                </div>
                <div className="col-3">
                                    <div className="form-group ">
                                    <label htmlFor="zipTo">Postnummer</label>
                                    <input 
                                    type="text" 
                                    name="zipTo"
                                    className="form-control" 
                                    id="zipTo" 
                                    aria-describedby="zipToHelp" 
                                    placeholder="Adresse" 
                                    onChange={this.handleChange}
                                    />
                                    <small id="zipToHelp" className="form-text  text-danger"> {errors.body ? errors.body : null} </small>     
                                    </div>
                </div>
            </div>
<div className="row justify-content-between mt-4">
    <div className="col text-center"> <Button variant="secondary" onClick={this.handleClose}>Lukk</Button></div>
    <div className="col text-center"><button type="submit" className="btn btn-success" disabled={loading}>{loading? <div className="spinner-border" role="status" disabled={loading}></div> : "Legg ut"}</button>
</div>
</div>
<p className="text-center my-2"><small>Last opp bilde etter av frakteoppdrag er publisert!</small></p>
</form>
                    </Modal.Body>
                    </Modal>
            </Fragment>
            </StyledDiv>
        )
    }


}

const mapStateToProps = (state)=>({
    UI: state.UI
})



export default connect(mapStateToProps,{postJob, clearErrors}) (PostJob)





const StyledDiv = styled.div`

.addButton{
    margin-top: 17px;;
    cursor: pointer;
    font-size: 32px;
    color:black;
    @media (max-width: 600px) {
      font-size: 28px;
  }  
  @media (max-width: 360px) {
      font-size: 22px;
  } 
}

.addButton:hover{
    transition: all .2s ease-in-out; 
transform: scale(1.1); 
}

.modal-content{
    width: 90%;
}

.form p {
    margin-bottom: 5px;
}

.modal-dialog{
    max-width:80%;
}


`
