// User reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ';

//UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_DATA = 'LOADING_DATA';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';

//data reducer types 
export const SET_JOBS = 'SET_JOBS';
export const SET_VURDERINGER = 'SET_VURDERINGER';
export const SET_JOB = 'SET_JOB';
export const DELETE_JOB = 'DELETE_JOB';
export const POST_JOB = 'POST_JOB';
export const POST_VURDERING = 'POST_VURDERING';
export const DELETE_VURDERING = 'DELETE_VURDERING';
export const SET_JOB_WITH_MESSAGES = 'SET_JOB_WITH_MESSAGES';
export const CHANGEJOBSTATUS = 'CHANGEJOBSTATUS';
export const POST_COMPANY = 'POST_COMPANY';
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_COMPANY = 'SET_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
