import React, { Component } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';



export default class Message extends Component {
   
    render() {
        dayjs.extend(relativeTime)

        const {message:{
            body,
            createdAt,
            userEmail,
            userImage
        }} = this.props;

        const defaultUserImage = <img className="defaultProfileImg" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" alt="userprofileimg"/>

        return (
            <StyleWrapper>
            <div className="text chatWrapper">
                <span>{ userImage ? <img className="defaultProfileImg" src={userImage} alt="userImage"/>  : defaultUserImage }</span>
                <span className="userEmail">{userEmail}</span> 
                <span className="date">{dayjs(createdAt).fromNow()} </span>
                <div className="speech-bubble">{body} </div>
            </div>
            </StyleWrapper>

            
        )
    }
}


const StyleWrapper = styled.div`


.date{
    color: gray;
    font-size: 12px;
}

.speech-bubble {
	position: relative;
	background: #00aabb;
	border-radius: .6em;
    margin-top: 15px;
    padding:10px;
    color:white;
    font-weight: 500;
}
.speech-bubble:after {
	content: '';
	position: absolute;
	top: 0;
	left: 5%;
	width: 0;
	height: 0;
	border: 25px solid transparent;
	border-bottom-color: #00aabb;
	border-top: 0;
	border-left: 0;
	margin-left: 5.5px;
	margin-top: -15px;
}

.chatWrapper{
    margin-bottom: 35px;
}

.userEmail{
    font-weight: 700;
    color: #747474;
}


.defaultProfileImg{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin:10px;
    
}
`


