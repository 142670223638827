import React, { Component } from 'react';
import styled from 'styled-components';
import Job from '../components/Job';
import {connect} from "react-redux";
import {getJobs} from "../redux/actions/dataActions";
import Map from '../components/Map';
import {ProductConsumer} from "../context/context";
import Split from 'react-split';
import SearchJobMap from "../components/SearchJobMap"
import mapSort from "../mapSort.png"
//import MapNewest from "../components/MapNewest";
//import { withScriptjs } from "react-google-maps";

//const MapLoader = withScriptjs(MapNewest);



export class home extends Component {
    state={
        winWidth:0,
        sortButtonShow:true,
        sortButtonSmallShow:true
    }

    componentDidMount(){
        this.props.getJobs();
        let winWidth = window.innerWidth;
        this.setState({winWidth: winWidth})

        window.addEventListener('scroll', this.handleScroll);
    }

    
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    handleScroll =()=> {
        let y = window.scrollY;
        this.setState({sortButtonShow:y})
       }

 


    render() {
        const { loading } = this.props.data;

        return (
            <ProductConsumer>
              {value => {
                const { search,searchTo,handleSearchFrom,handleSearchTo,filteredJobs, handleResetJobSearche, handleOpenMap, mapOpen } = value;
        return (
<StyledDiv open={mapOpen} show={this.state.sortButtonShow} showSmall={this.state.sortButtonSmallShow}>

{this.state.winWidth > 700 ? (<Split
    className="split"
    sizes={[40, 60]}
>
    <div>
 
   <Map/>
    </div>
    <div className="mx-1">
    <div className="container-fluid">
    <div className="row holder">
        <div className="col-3 input-effect px-0">
        	<input className="effect-20" type="text" placeholder="" value={search} onChange={(handleSearchFrom)}/>
            <label>Fra</label>
            <span className="focus-border">
            	<i></i>
            </span>
        </div>
        <div className="col-3 input-effect  px-0">
        	<input className="effect-20" type="text" placeholder="" value={searchTo} onChange={(handleSearchTo)}/>
            <label>Til</label>
            <span className="focus-border">
            	<i></i>
            </span>
            </div>
            <div className='searchJobMap'  onClick={handleOpenMap}>
            <SearchJobMap/>
         
            <span className='sorter'>
            <img src={mapSort}/>
            </span>

            
            </div>


            <div className='d-flex flex-row-reverse my-1'>
               <button type="button" className="btn btn-outline-primary" onClick={handleResetJobSearche}>Nullstill søk</button>
 
      </div>

            </div>
            </div>
            {console.log(filteredJobs.length)}
   
        {!loading ? filteredJobs.length > 0 ? (filteredJobs.map((job)=>{return <Job key={job.jobId} job={job}/>})) : <p>Ingen aktive jobber</p> : <p>Loading...</p> }
    </div>
</Split>)
:
(
   <div className="container-fluid">
    <div className="row">
    
        <div className="col-12 col-sm-12 col-md-6 px-0 mapyo">
        <Map />
        </div>
        <div className="container-fluid">
    <div className="row">
        <div class="col-3 input-effect px-0">
        	<input class="effect-20" type="text" placeholder="" value={search} onChange={(handleSearchFrom)}/>
            <label>Fra</label>
            <span class="focus-border">
            	<i></i>
            </span>
        </div>
        <div class="col-3 input-effect  px-0">
        	<input class="effect-20" type="text" placeholder="" value={searchTo} onChange={(handleSearchTo)}/>
            <label>Til</label>
            <span class="focus-border">
            	<i></i>
            </span>
            </div>
            <div className='searchJobMap'  onClick={handleOpenMap}>
            <SearchJobMap/>
            <span className='sorterSmall'>
            <img src={mapSort}/>
            </span>
            </div>
            <div className='d-flex flex-row-reverse my-1'>
               <button type="button" className="btn btn-outline-primary" onClick={handleResetJobSearche}>Nullstill søk</button>
            </div>
            
            </div></div>
        <div className="col-12 col-sm-12 col-md-6">
        
                    {!loading ? ( filteredJobs.map((job)=>{return <Job key={job.jobId} job={job}/>})) : <p>Loading...</p> }
        </div>
    </div>

</div> 
)
}


   
   
  {/*
    */} 
</StyledDiv>
        )
    }}
      </ProductConsumer>
        )
 
    }
}

const mapStateToProps = state =>({
    data:state.data
})

export default connect(mapStateToProps,{getJobs})(home)


const StyledDiv = styled.div`

.holder{
 

}
.split {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

.gutter {
    background-color: #eee;
    background-repeat: no-repeat;
    background-position: 50%;
    padding-right: 10px;
}

.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
    z-index:100;
}



h2 {
    font-family: 'Damion', cursive;
    font-weight: 400;
    color: #4caf50;
    font-size: 35px;
    text-align: center;
    position: relative;
}
h2:before {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    top: 22px;
    background: #4caf50;
    height: 1px;
}
h2 i {
    font-style: normal;
    background: #fff;
    position: relative;
    padding: 10px;
}
*:focus{outline: none;}

/* necessary to give position: relative to parent. */
input[type="text"]{font: 15px/24px 'Muli', sans-serif; color: #333; width: 100%; box-sizing: border-box; letter-spacing: 1px;}

*:focus{outline: none;}

.col-3{float: left; width: 40.33%; margin: 1px 3%; position: relative;} /* necessary to give position: relative to parent. */
input[type="text"]{font: 15px/24px "Lato", Arial, sans-serif; color: #333; width: 100%; box-sizing: border-box; letter-spacing: 1px;}

.effect-20
{border: 1px solid #ccc; padding: 7px 14px; transition: 0.4s; background: transparent;}

.effect-20 ~ .focus-border:before,
.effect-20 ~ .focus-border:after{content: ""; position: absolute; top: 0; left: 0; width: 0; height: 2px; background-color: #4caf50; transition: 0.3s;}
.effect-20 ~ .focus-border:after{top: auto; bottom: 0; left: auto; right: 0;}
.effect-20 ~ .focus-border i:before,
.effect-20 ~ .focus-border i:after{content: ""; position: absolute; top: 0; left: 0; width: 2px; height: 0; background-color: #4caf50; transition: 0.4s;}
.effect-20 ~ .focus-border i:after{left: auto; right: 0; top: auto; bottom: 0;}
.effect-20:focus ~ .focus-border:before,
.effect-20:focus ~ .focus-border:after,
.has-content.effect-20 ~ .focus-border:before,
.has-content.effect-20 ~ .focus-border:after{width: 100%; transition: 0.3s;}
.effect-20:focus ~ .focus-border i:before,
.effect-20:focus ~ .focus-border i:after,
.has-content.effect-20 ~ .focus-border i:before,
.has-content.effect-20 ~ .focus-border i:after{height: 100%; transition: 0.4s;}
.effect-20 ~ label{position: absolute; left: 14px; width: 100%; top: 10px; color: #aaa; transition: 0.3s; z-index: -1; letter-spacing: 0.5px;}
.effect-20:focus ~ label, .has-content.effect-20 ~ label{top: -18px; left: 0; font-size: 12px; color: #4caf50; transition: 0.3s;}



.searchJobMap{
    position:fixed;
    z-index:100;
    right:0px;
    top:60px;
    height:220px;
    width:200px;
    border-radius:5px;
    background-color:#FFB20C ;
    transform: ${props => (props.open ? "translateX(0)" : "translateX(100%)")};
    transition: all 0.5s ease;

}
.sorter{
    position:fixed;
    left:-40px;
    top:20px;
    transform: ${props => (props.show < 10 ? "translateY(0%)" : "translateY(-200%)")};
    transition: all 0.5s ease;


}

.sorterSmall{
    position:fixed;
    left:-35px;
    top:300px;
    transform: ${props => (props.show < 100 ? "translateY(0%)" : "translateY(-1000%)")};
    transition: all 0.5s ease;

}

`