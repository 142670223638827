import React from "react";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import styled from 'styled-components';





const StaticProfile = (props)=>{
    const {profile:{email, createdAt, bio,firstName,lastName, imageUrl}} = props;
    const defaultUserImage = <img className="defaultProfileImgProfile" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" alt="userprofileimg"/>



    return(

        <StyledDiv>
        
        <div className="card text-center">
                
        <div className="card-body"> 
        <div className="wrapper my-3">
        { imageUrl ? <img className="defaultProfileImgProfile" src={imageUrl} alt="wtf"/>  : defaultUserImage }

        </div>
        <div className="my-3">
        <Link  to={`/users/${email}`}> {email} </Link> 

        </div>
         <div className="my-3"><span>{firstName}</span> <span>{lastName}</span></div>
         <p className="card-text"> {bio} </p>
         </div>
         <div className="card-footer text-muted">
         </div>
        Registrert { dayjs(createdAt).format('MMM YYYY') }
   
        </div>
         </StyledDiv>
    )

}

export default StaticProfile


const StyledDiv = styled.div`


.defaultProfileImgProfile{
    margin-right: 20px;
    width: 50%;
    height:50%;
    border-radius: 5%;
}
.wrapper{
    display: block;

}
`