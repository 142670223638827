import React, { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Job from "../components/Job";
import {connect} from "react-redux";

import {getUserData} from "../redux/actions/dataActions";
import StaticProfile from '../components/StaticProfile';
import Vurderinger from '../components/Vurderinger';
import NyVurdering from '../components/NyVurdering';



class user extends Component {
    state = {
        profile:null,
        vurderinger:[]
    }
    componentDidMount(){
        const email = this.props.match.params.email;


        this.props.getUserData(email);
        axios.get(`/user/${email}`)
        .then(res=>{
            this.setState({
                profile:res.data.user,
                vurderinger:res.data.vurderinger
            });
        })
        .catch(err=>console.log(err))
    }

    
    render() {
        const {  
    user: {
        credentials }
    } = this.props;

        const sumRatingLength = this.state.vurderinger.length
        const sumRating = this.state.vurderinger.length !== 0 ? ( this.state.vurderinger.map(item=>item.rating).reduce((a, b) => a + b) ) : null 
        const snittRating = sumRating/sumRatingLength

        const {jobs,loading}=this.props.data;

        const activeJobber =  jobs.filter((item)=>{return item.active === true})


        const currentDate = new Date();
        const currentDateTime = currentDate.getTime();
        const last30DaysDate = new Date(currentDate.setDate(currentDate.getDate() - 30));
        const last30DaysDateTime = last30DaysDate.getTime();

        const last30DaysList = activeJobber.filter(x => {
          const elementDateTime = new Date(x.createdAt).getTime();
          if (elementDateTime <= currentDateTime && elementDateTime > last30DaysDateTime) {
            return true;
          }
          return false
        }).sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        const older30DaysList = activeJobber.filter(x => {
          const elementDateTime = new Date(x.createdAt).getTime();
          if (elementDateTime <= currentDateTime && elementDateTime > last30DaysDateTime) {
            return false;
          }
          return true
        }).sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

       const fullforteJobberList =  jobs.filter((item)=>{return item.active === false})


        const jobsMarkup = loading ? (
            <p>loading data...</p>
        ) : (
            last30DaysList.length === 0 ? (
            <p>ingen aktive fraktejobber</p>
        ) : 
       (
            last30DaysList.map((job)=> <Job key={job.jobId} job={job} />)
        ))

        const utgåtteJobber = loading ? (
            <p>loading data...</p>
        ) : (
            older30DaysList.length === 0  ? (
                <p></p>
                ) :
                (<div className="col-12 col-sm-12 col-md-6 my-5 utgåtteJobber">
                <div className="utgåtteJobberOverlay">Utgått</div>
                <h4>Utgåtte jobber (eldre enn 30 dager)</h4>
              
                    {older30DaysList.map((job)=> <Job key={job.jobId} job={job}  />)}
                </div>)
        )
        
        const fullforteJobber = loading ? (
            <p>loading data...</p>
        ) : (
            fullforteJobberList.length === 0  ? (
                <p></p>
                ) :
                (<div className="col-12 col-sm-12 col-md-6 my-5 utgåtteJobber">
                <div className="utførteJobberOverlay"> fullførte jobber</div>
                <h4>Utførte jobber </h4>
              
                    {fullforteJobberList.map((job)=> <Job key={job.jobId} job={job}  />)}
                </div>)
        )
  

        return (
            <StyledDiv>
         
            <div className="container">
            <div className="row">
                <div className="col-12 col-sm-12 col-md-6">
                    {this.state.profile === null ? (
                        <p>Loading profile...</p>
                    ): (
                        <div>
                        <StaticProfile profile={this.state.profile}/>
                        </div>
                        )
                    }
                    
                    {this.state.vurderinger === null ? (
                        <p>Loading vurderinger...</p>
                    ): (
                        <div className="row my-5">
                       {this.props.match.params.email === credentials.email ? (null): (<NyVurdering/>) } 

                        { isNaN(snittRating) ? (<p className="my-3">Bruker har ingen vurderinger enda.</p>):( sumRatingLength <= 1 ? (
                    <h5 className="text-center">{snittRating.toFixed(1)} <small className="text-muted"> ({sumRatingLength} vurdering) </small> </h5>
                ) : ( 
                    <h5 className="text-center">{snittRating.toFixed(1)} <small className="text-muted"> ({sumRatingLength} vurderinger) </small> </h5>
                ) )
                            
                    
                }
                        {this.state.vurderinger !== null ? (this.state.vurderinger.map((vurdering) => <Vurderinger key={vurdering.vurderingId} vurdering={vurdering} snittRating={snittRating} sumRatingLength={sumRatingLength} />)) : <p>Ingen vurderinger</p>}
                        </div>
                        )
                    }
                    
                </div> 
                <div className="col-12 col-sm-12 col-md-6">{jobsMarkup}</div>
            </div>
            <div className="row my-5">
             {utgåtteJobber}
             {fullforteJobber}
            </div>
        </div>
        </StyledDiv>
        )
    }
}

const mapStateToProps = state =>({
    data:state.data,
    user:state.user,

})



export default connect(mapStateToProps, {getUserData}) (user)


const StyledDiv = styled.div`
    margin-top:80px;

.container-fluid{
    margin-top:80px;
}

.utgåtteJobber{
    position: relative;
    @media (max-width: 750px) {
    margin-top: 30px;
  }
}
.utgåtteJobberOverlay{
    font-weight: 700;
    font-size: 40px;
    transform: rotate(-20deg);
    color: red;
    position: absolute;
    top:2%;
    left:30%;
    height: 20%;
    z-index: 400;
}

.utførteJobberOverlay{
    font-weight: 700;
    font-size: 30px;
    transform: rotate(-20deg);
    color: darkgreen;
    position: absolute;
    top:2%;
    left:22%;
    height:10%;
    z-index: 400;
}

`