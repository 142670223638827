import React, { Component } from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { logoutUser, uploadImage } from "../redux/actions/userActions";
import EditDetails from "../components/EditDetails";
import Job from "../components/Job";



class profile extends Component {

    handleImageChange = (event) => {
        const image = event.target.files[0];
        const formData = new FormData();
        formData.append('image', image, image.name);
        this.props.uploadImage(formData);
        
    };

    handleEditPicture = () =>{
        const fileInput = document.getElementById('imageInput');
        fileInput.click();
    }

    handleLogout = ()=>{
       this.props.logoutUser(); 
    }



    render() {
        dayjs.extend(relativeTime)
        const { user: {credentials: {email, firstName, lastName, createdAt, bio,imageUrl }, loading, authenticated}} = this.props;
        const defaultUserImage = <img className="defaultProfileImgProfile" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" alt="userprofileimg"/>

        const {jobs}=this.props.data;

        let ownJobs = jobs.filter(function(jobs) {
            return jobs.userEmail === email });

            const currentDate = new Date();
            const currentDateTime = currentDate.getTime();
            const last30DaysDate = new Date(currentDate.setDate(currentDate.getDate() - 29));
            const last30DaysDateTime = last30DaysDate.getTime();
    
            const last30DaysList = ownJobs.filter(x => {
              const elementDateTime = new Date(x.createdAt).getTime();
              if (elementDateTime <= currentDateTime && elementDateTime > last30DaysDateTime) {
                return true;
              }
              return false
            }).sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
    
            const older30DaysList = ownJobs.filter(x => {
              const elementDateTime = new Date(x.createdAt).getTime();
              if (elementDateTime <= currentDateTime && elementDateTime > last30DaysDateTime) {
                return false;
              }
              return true
            }).sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
            

        const jobsMarkup = loading ? (
            <p>loading data...</p>
        ) : (
            last30DaysList.length === 0 ? (
            <p>ingen aktive fraktejobber</p>
        ) : 
       (
        last30DaysList.map((job)=> <Job key={job.jobId} job={job} />)
        ))

        const utgåtteJobber = loading ? (
            <p>loading data...</p>
        ) : (
            older30DaysList.length === 0  ? (
                <p></p>
                ) :
                (<div className="col-12 col-sm-12 col-md-6 my-5 utgåtteJobber">
                <div className="utgåtteJobberOverlay">Utgått</div>
                <h4>Utgåtte jobber (eldre enn 30 dager)</h4>

                    {older30DaysList.map((job)=> <Job key={job.jobId} job={job}  />)}
                </div>)
        )


        let probileMarkup = !loading ? (authenticated ?  (
            <>

            <div className="row">
                <div className="col-12 col-sm-12 col-md-6">
                    <div className="card text-center">
                
                    <div className="card-body"> 
                    <div className="wrapper my-3">
                    { imageUrl ? <img className="defaultProfileImgProfile" src={imageUrl} alt="wtf"/>  : defaultUserImage }
                    <input type="file" id="imageInput" onChange={this.handleImageChange} hidden="hidden" />
                    <FontAwesomeIcon icon={faCamera} onClick={this.handleEditPicture} className="iconButton" data-bs-toggle="tooltip" data-bs-placement="left" title="Bytt/legg til profilbilde"/>
                    </div>
                    <div className="my-3">
                        <Link  to={`/users/${email}`}> {email} </Link> 

                    </div>
                     <div className="my-3"><span>{firstName}</span> <span>{lastName}</span></div>
                     <p className="card-text"> {bio} </p>
                     </div>
                     <div className="card-footer text-muted">
                     <EditDetails/>
                     </div>
                    Registrert { dayjs(createdAt).format('MMM YYYY') }
                    <div>
                        <button className="btn btn-danger my-3" data-bs-toggle="tooltip" data-bs-placement="left" title="Logg ut" onClick={this.handleLogout}>Logg ut</button>
                    </div>
                     </div>

                </div>
                    <div className="col-12 col-sm-12 col-md-6">
                        {jobsMarkup}
                    </div>
            </div>
            <div className="row my-5">
             {utgåtteJobber}
            </div>
            </>
                 
        ) : (
            <div>      
            <div className="card text-center">    
            <div className="card-body"> 
            <h5>Ingen profil funnet</h5>
            <Link to="/login"> <button className="btn btn-primary m-4">Logg inn</button></Link>
            <Link to="/signup"> <button className="btn btn-secondary">Registrer</button></Link>

            </div> 
            </div> 
            </div>
        )) : (<p>loading...</p>)
        return (
            <StyledDiv>
            <div className="container">
            {probileMarkup}

            </div>
                
            </StyledDiv>
        )
    }
}

const mapStateToProps =(state)=>({
    user:state.user,
    data:state.data

});

const mapActionsToProps = { logoutUser, uploadImage }


export default connect(mapStateToProps,mapActionsToProps)(profile)



const StyledDiv = styled.div`
    margin-top:80px;

    .defaultProfileImgProfile{
    margin-right: 20px;
    width: 50%;
    height:50%;
    border-radius: 5%;
}

.wrapper{
    display: block;

}
.iconButton{
    position: absolute;
    right:48%;
    color:lightgray;
    filter: drop-shadow(0px 0px 3px black);
}
.iconButton:hover{
    cursor: pointer;
}
.btn-danger{
    margin:auto;
    width: 100px;
}
.utgåtteJobber{
    position: relative;
    @media (max-width: 750px) {
    margin-top: 30px;
  }
}
.utgåtteJobberOverlay{
    font-weight: 700;
    font-size: 40px;
    transform: rotate(-20deg);
    color: red;
    position: absolute;
    top:2%;
    left:30%;
    height: 20%;
    z-index: 400;
}

`