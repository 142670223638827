import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";
import {changeJobStatus} from "../redux/actions/dataActions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { Modal } from "react-bootstrap";



 

 class ChangeJobStatus extends Component {
     state={
         open:false
     }

     handleOpen = () =>{
         this.setState({open:true})
     }
     handleClose = () =>{
        this.setState({open:false})
    }
    changeJobStatus = () =>{
        this.props.changeJobStatus(this.props.jobId);
        this.setState({open:false})
    }
    render() {
        return (
            <StyledDiv>
            <Fragment>
         <FontAwesomeIcon icon={faCheckSquare} onClick={this.handleOpen}  className="checkButton" data-bs-toggle="tooltip" data-bs-placement="left" title="Marker jobb som utført"/>


<Modal className="modalOverlay d-flex align-items-center justify-content-center" show={this.state.open} >
<Modal.Header closeButton onClick={this.handleClose}>
            <Modal.Title> Sett jobb som utført </Modal.Title>
          </Modal.Header>
          <Modal.Body>
                    <div className="">
                    <p>Du er i ferd med å sette jobb som utført, jobb vil ikke lenger vises.</p>
<button className="btn btn-danger" onClick={this.changeJobStatus}>Jobb utført</button>
                    </div>

          </Modal.Body>
        </Modal>

         </Fragment>
            </StyledDiv>
        )
    }
}


export default connect(null, {changeJobStatus})(ChangeJobStatus)

const StyledDiv = styled.div`
position: absolute;
right:2%;
top:13%;

.checkButton{
    margin-left: 30px;
    color: darkgreen;
    cursor: pointer;
    z-index: 500;
}
.checkButton:hover{
 transition: all .2s ease-in-out; 
transform: scale(1.1); 
}

`
