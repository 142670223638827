import React, { Component } from 'react'
import styled from 'styled-components';
import {Link} from "react-router-dom";

//redux stuff
import { connect } from 'react-redux';
import {loginUser } from '../redux/actions/userActions';






export class login extends Component {


    constructor() {
        super();
        this.state = {
          email: '',
          password: '',
          errors: {}
        };
      }

      componentWillReceiveProps(nextProps) {
        if (nextProps.UI.errors) {
          this.setState({ errors: nextProps.UI.errors });
        }
      }
handleChange = (event) =>{
    this.setState({
        [event.target.name]:event.target.value
    })
}
handleSubmit = (event)=>{
    event.preventDefault();

    const userData = {
        email:this.state.email,
        password:this.state.password
    };
    this.props.loginUser(userData, this.props.history);
    

}


    render() {
const {errors } = this.state;
const { UI: {loading} } = this.props;

        return (
            <StyledDiv>
            
            <div className="container">
            <div className="row justify-content-center">
            <div className="col col-sm-10 col-xs-10 col-lg-4 text-center">
            <h1>Logg inn</h1>

                <form  noValidate onSubmit={this.handleSubmit}>
                <div className="form-group my-3">
                    <label htmlFor="InputEmail">Epost</label>
                    <input 
                    type="email" 
                    name="email"
                    className="form-control" 
                    id="email" 
                    aria-describedby="emailHelp" 
                    placeholder="Din Epost" 
                    value={this.state.email} 
                    onChange={this.handleChange}/>
                    
                    <small id="emailHelp" className="form-text  text-danger"> {errors.email } </small>
                </div>
                <div className="form-group">
                    <label htmlFor="InputPassword">Passord</label>
                    <input 
                    type="password" 
                    name="password"
                    className="form-control" 
                    id="password" 
                    placeholder="Passord"  
                    value={this.state.password} 
                    onChange={this.handleChange}/>

                    <small id="passwordHelp" className="form-text  text-danger"> {errors.password ? errors.password : null} </small>

                </div>
                <div className="form-check">
                    <input type="checkbox" className="form-check-input float-none" id="remember"/>
                    <label className="form-check-label" htmlFor="remember">Husk meg</label>
                </div>
                <div>
                <small id="" className="form-text text-danger"> {errors.general} </small>
                </div>
                <button 
                type="submit" 
                disabled={loading}
                className="btn btn-primary mt-3 btnSubmitForm"> {loading? <div className="spinner-border" role="status"></div> : "Logg inn"}</button>
                </form>

                <br/>
                <small>Har du ikke konto? Registrer konto <Link to="/signup">her</Link></small>

            </div>
            </div>
                
            </div>
            </StyledDiv>
        )
    }
}



const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI
  });
  
  const mapActionsToProps = {
    loginUser
  };

export default connect(
    mapStateToProps,
    mapActionsToProps
  )((login));

const StyledDiv = styled.div`
    margin-top:80px;


.form-check-label{
    margin-left:6px;
}
.spinner-border{
    height:20px;
    width: 20px;
}

`