import React, { Component } from 'react'
import {ProductConsumer} from "../context/context";
import styled from 'styled-components';

import nord from "../norge/nord.png"
import mid from "../norge/mid.png"
import east from "../norge/east.png"
import west from "../norge/west.png"


export default class SearchJobMap extends Component {
  render() {
    return (

        <ProductConsumer>
        {value => {
          const {  handleJobNord, handleJobMid, handleJobEast, handleJobWest } = value;
  return (
      <StyledDiv>

            <div className='mapWrapper'>
            <img src={nord} className="nord" onClick={handleJobNord} data-bs-toggle="tooltip" data-bs-placement="left" title="Nord-Norge"/>
            <img src={mid} className="mid" onClick={handleJobMid} data-bs-toggle="tooltip" data-bs-placement="left" title="Midt-Norge"/>
            <img src={east} className="east" onClick={handleJobEast} data-bs-toggle="tooltip" data-bs-placement="left" title="Øst"/>
            <img src={west} className="west" onClick={handleJobWest} data-bs-toggle="tooltip" data-bs-placement="left" title="Vest"/>
            <p className='p'>Område ting sendes fra</p>


</div>

      
      </StyledDiv>
      );
        }}
      </ProductConsumer>


        )
  }
}



const StyledDiv = styled.div`
    overflow:hidden ;



.nord{
position: absolute;
top:2;
left:60px;
cursor: pointer;
}
.nord:hover{
  filter: brightness(0) saturate(100%) invert(27%) sepia(78%) saturate(1701%) hue-rotate(90deg) brightness(90%) contrast(107%);
}

.mid{
  position: absolute;
  top:57px;
  left:30px;
  cursor: pointer;
}
.mid:hover{
  filter: brightness(0) saturate(100%) invert(27%) sepia(78%) saturate(1701%) hue-rotate(90deg) brightness(90%) contrast(107%);
}

.east{
  position: absolute;
  top:116px;
  left:12px;
  cursor: pointer;

}
.east:hover{
  filter: brightness(0) saturate(100%) invert(27%) sepia(78%) saturate(1701%) hue-rotate(90deg) brightness(90%) contrast(107%);
}

.west{
  position: absolute;
  top:108px;
  left:0px;
  cursor: pointer;

}
.west:hover{
  filter: brightness(0) saturate(100%) invert(27%) sepia(78%) saturate(1701%) hue-rotate(90deg) brightness(90%) contrast(107%);
}

.mapWrapper{

}
.p{
    position: absolute;
  bottom:-4px;
  left:2px;
}
`
