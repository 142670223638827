
import React, { Component  } from 'react'
import axios from 'axios';



const ProductContext = React.createContext();


class ProductProvider extends Component {
    state={
        allJobs: [],
        filteredJobs:[],
        currentJobs:[],
        olderJobs:[],
        search: "",
        searchTo:"",
        mapData:null,
        startLat: null,
        startLng:null,
        endLat:null,
        endLng:null,
        center:{lat:65, lng:14},
        activeJob: false,
        zoomLvl: 5,
        start:null,
        end:null,
        messages:null,
        messagesOpen:false,
        loading:true,
        dLat:null,
        dLng:null,

        companies:[],
        filteredCompanies:[],
        nameSearch:"",
        companyBy:"",
        companyZip:"",

        mapOpen:false,




        loadingCompanies: true

    };

async componentDidMount(){
    try{
        const response = await fetch(
            "https://europe-west1-minfrakt.cloudfunctions.net/api/jobs"
        );
        const data = await response.json()
        this.setState({ allJobs: data });

        const currentDate = new Date();
        const currentDateTime = currentDate.getTime();
        const last30DaysDate = new Date(currentDate.setDate(currentDate.getDate() - 29));
        const last30DaysDateTime = last30DaysDate.getTime();

        const last30DaysList = data.filter(x => {
          const elementDateTime = new Date(x.createdAt).getTime();
          if (elementDateTime <= currentDateTime && elementDateTime > last30DaysDateTime) {
            return true;
          }
          return false
        }).sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        const older30DaysList = data.filter(x => {
          const elementDateTime = new Date(x.createdAt).getTime();
          if (elementDateTime <= currentDateTime && elementDateTime > last30DaysDateTime) {
            return false;
          }
          return true
        }).sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });


        
        
        //        let unique = [...new Map(activeMessagesReverse.map((item)=>[item["recipientEmail"],item])).values(),]
        this.setState({filteredJobs:last30DaysList});
        this.setState({currentJobs:last30DaysList })
        this.setState({olderJobs:older30DaysList});

    }
    catch (error) {
        console.error("Failed to load jobs FFS");
      }


//      try{
//        const response = await fetch(
//            "https://europe-west1-minfrakt.cloudfunctions.net/api/companies"
//        );
//        const data = await response.json()
//        this.setState({ companies: data });
//
//        this.setState({filteredJobs:data});
//
//    }
//    catch (error) {
//        console.error("Failed to load jobs FFS");
//      }

     
}


handleClickedJob = async (jobId)=>{
  let mapData;
  this.setState({dLat:60, dLng:18})
    let job = this.state.filteredJobs.find((item)=> item.jobId === jobId);
    let streetNameFrom = job.streetNameFrom.replaceAll(" ","+");
    let cityFrom = job.cityFrom;
    let zipFrom = job.zipFrom
        let streetNameTo = job.streetNameTo.replaceAll(" ","+");
        let cityTo = job.cityTo;
        let zipTo = job.zipTo;
        this.setState({activeJob:true})
       
       
        let url = `https://maps.googleapis.com/maps/api/directions/json?origin=${streetNameFrom+"+"+zipFrom+"+"+cityFrom}&destination=${streetNameTo+"+"+zipTo+"+"+cityTo}&key=${process.env.REACT_APP_API_KEY}`
        let cors = `https://proxyfrakt.herokuapp.com/`

               // async workeing? looks like it       
        let res = await axios.get(cors+url)
          let data = res.data
          this.setState({mapData:data.routes[0].legs[0]})
      
        try{
            const locationsData = this.state.mapData;
            const startData = locationsData.start_location || "default start_location"
            const startLat = startData.lat || "default lat"
            const startLng = startData.lng || "default lng"
            const endData = locationsData.end_location || "default end_location"
            const endLat = endData.lat || "asdf"
            const endLng = endData.lng || "asdf"
            this.setState({startLat:startLat})
            this.setState({startLng:startLng})
            this.setState({endLat:endLat})
            this.setState({endLng:endLng})
            this.setState({start:{lat:startLat, lng:startLng}})
            this.setState({end:{lat:endLat, lng:endLng}})

          const center = {lat:((startLat+endLat)/2), lng:((startLng+endLng)/2)}
            this.setState({center:center})
            
            this.handleZoom(startLat, endLat)
            this.setState({dLat:null, dLng:null})
        }
        catch(error){
            console.log(error)
        }
       
}


    handleZoom = (startLat, endLat)=>{
      let number = startLat -endLat;
      let range = Math.abs(number)

      if(range > 7){
        this.setState({zoomLvl:5})
      } else if(range > 5){
      this.setState({zoomLvl:6})
      } else if(range > 3){
        this.setState({zoomLvl:7})
      } else if (range < 3 ){
        this.setState({zoomLvl:7})
      }
    }

    getMessagesData = async ()=>{
      try{  
        let response = await axios.get(`/activeMessages`)
         const activeMessages = response.data;
         console.log(activeMessages)
         let activeMessagesReverse = [...activeMessages.reverse()]
         let unique = [...new Map(activeMessagesReverse.map((item)=>[item["recipientEmail"],item])).values(),]
         let unique2 = [...new Map(unique.map((item)=>[item["userEmail"],item])).values(),]



         this.setState({messages:unique2})
         this.setState({loading:false})
      }
      catch(error){
          console.error("Failed to load messages FFS");
      }
  }

    handleOpenMessages = () => {
      this.setState({ messagesOpen: !this.state.messagesOpen });
      this.getMessagesData();
    };



    handleSearchFrom = (event) =>{
      this.setState({search:event.target.value},this.handleSearch);
    };

    handleSearch = ()=>{
      const {filteredJobs, search} = this.state;
      let tempJobs = [...filteredJobs]
           if(search.length > 0) {
          tempJobs = tempJobs.filter((item) => {
          let tempSearch = search.toLocaleLowerCase();
          let tempCityFrom = item.cityFrom.toLocaleLowerCase().slice(0, search.length);
          if (tempSearch === tempCityFrom) {
            return item;
          }
          return false;            
        });
            
    } 
    this.setState({filteredJobs:tempJobs})
    if(search.length ===0){
      this.setState({filteredJobs:filteredJobs})
    }
}
 
handleSearchTo = (event) =>{
  this.setState({searchTo:event.target.value},this.handleSearch2);
};

handleSearch2 = ()=>{
  const {filteredJobs, searchTo} = this.state;
  let tempJobs = [...filteredJobs]
       if(searchTo.length > 0) {
      tempJobs = tempJobs.filter((item) => {
      let tempSearch = searchTo.toLocaleLowerCase();
      let tempCityTo = item.cityTo.toLocaleLowerCase().slice(0, searchTo.length);
      if (tempSearch === tempCityTo) {
        return item;
      }
      return false;            
    });
        
} 
this.setState({filteredJobs:tempJobs})
if(searchTo.length ===0){
  this.setState({filteredJobs:filteredJobs})
}
}


getAllCompanies = async ()=>{
  try{  
    let response = await axios.get(`/companies`)
     const companies = response.data;
     console.log(companies)

     this.setState({
       companies:companies,
       filteredCompanies:companies,
       loadingCompanies:false
    })
  }
  catch(error){
      console.error("Failed to load companies FFS");
  }
}

// søk company name
handleSearchCompanyNameInput = (event) =>{
  this.setState({nameSearch:event.target.value},this.handleSearchCompanyName);
};

handleSearchCompanyName = ()=>{
  const {companies, nameSearch} = this.state;
  let tempCompanies = [...companies]
       if(nameSearch.length > 0) {
        tempCompanies = tempCompanies.filter((company) => {
      let tempSearch = nameSearch.toLocaleLowerCase();
      let tempCompanyName = company.companyName.toLocaleLowerCase().slice(0, nameSearch.length);
      if (tempSearch === tempCompanyName) {
        return company;
      }
      return false;            
    });
        
} 
this.setState({filteredCompanies:tempCompanies})
if(nameSearch.length ===0){
  this.setState({filteredCompanies:companies})
}
}


// søk company by
handleSearchCompanyLocationInput = (event) =>{
  this.setState({companyBy:event.target.value},this.handleSearchCompanyLocation);
};

handleSearchCompanyLocation = ()=>{
  const { companyBy, companies} = this.state;
  let tempCompanies = [...companies]
       if(companyBy.length > 0) {
        tempCompanies = tempCompanies.filter((company) => {
      let tempSearch = companyBy.toLocaleLowerCase();
      let tempCompanyName = company.companyLocation.toLocaleLowerCase().slice(0, companyBy.length);
      if (tempSearch === tempCompanyName) {
        return company;
      }
      return false;            
    });
        
} 
this.setState({filteredCompanies:tempCompanies})
if(companyBy.length ===0){
  this.setState({filteredCompanies:companies})
}
}


// søk company zip
handleSearchCompanyZipInput = (event) =>{
  this.setState({companyZip:event.target.value},this.handleSearchCompanyZip);
};

handleSearchCompanyZip = ()=>{
  const { companyZip, companies} = this.state;
  let tempCompanies = [...companies]
       if(companyZip.length > 0) {
        tempCompanies = tempCompanies.filter((company) => {
      let tempSearch = companyZip.toLocaleLowerCase();
      let tempCompanyName = company.companyZip.toLocaleLowerCase().slice(0, companyZip.length);
      if (tempSearch === tempCompanyName) {
        return company;
      }
      return false;            
    });
        
} 
this.setState({filteredCompanies:tempCompanies})
if(companyZip.length ===0){
  this.setState({filteredCompanies:companies})
}
}

handleResetCompanySearche =()=>{
  this.setState({
    nameSearch:"",
    companyBy:"",
    companyZip:""
  })
  this.getAllCompanies();
}

handleResetJobSearche =()=>{
  this.setState({
    search: "",
    searchTo:""
  })
  this.componentDidMount();
}

// Handle map company

handleNord = ()=>{
  const { companies } = this.state
  let tempCompanies = [...companies]
  let nordBy = []
  for(let i = 0; i < tempCompanies.length; i++){
      if(tempCompanies[i].companyZip.charAt(0) === "9" || tempCompanies[i].companyZip.charAt(0) === "8" ){
        nordBy.push(tempCompanies[i])
      }
  }
  this.setState({filteredCompanies:nordBy})
}

handleMid = ()=>{
  const { companies } = this.state
  let tempCompanies = [...companies]
  let midBy = []
  for(let i = 0; i < tempCompanies.length; i++){
      if(tempCompanies[i].companyZip.charAt(0) === "7" ){
        midBy.push(tempCompanies[i])
      }
  }
  this.setState({filteredCompanies:midBy})
}

handleEast = ()=>{
  const { companies } = this.state
  let tempCompanies = [...companies]
  let eastBy = []
  for(let i = 0; i < tempCompanies.length; i++){
      if(tempCompanies[i].companyZip.charAt(0) === "3" || tempCompanies[i].companyZip.charAt(0) === "2" || tempCompanies[i].companyZip.charAt(0) === "1" || tempCompanies[i].companyZip.charAt(0) === "0"){
        eastBy.push(tempCompanies[i])
      }
  }
  this.setState({filteredCompanies:eastBy})
}

handleWest = ()=>{
  const { companies } = this.state
  let tempCompanies = [...companies]
  let westBy = []
  for(let i = 0; i < tempCompanies.length; i++){
      if(tempCompanies[i].companyZip.charAt(0) === "6" || tempCompanies[i].companyZip.charAt(0) === "5" || tempCompanies[i].companyZip.charAt(0) === "4" ){
        westBy.push(tempCompanies[i])
      }
  }
  this.setState({filteredCompanies:westBy})
}


handleOpenMap =()=>{
  this.setState({mapOpen: !this.state.mapOpen})
}

// Handle map job

handleJobNord = ()=>{
  const { currentJobs } = this.state
  let tempJobs = [...currentJobs]
  let jobsNord = []
  for(let i = 0; i < tempJobs.length; i++){
      if(tempJobs[i].zipFrom.charAt(0) === "9" || tempJobs[i].zipFrom.charAt(0) === "8" ){
        jobsNord.push(tempJobs[i])
      }
  }
  this.setState({filteredJobs:jobsNord})
}

handleJobMid = ()=>{
  const { currentJobs } = this.state
  let tempJobs = [...currentJobs]
  let jobsMid = []
  for(let i = 0; i < tempJobs.length; i++){
      if(tempJobs[i].zipFrom.charAt(0) === "7" ){
        jobsMid.push(tempJobs[i])
      }
  }
  this.setState({filteredJobs:jobsMid})
}

handleJobEast = ()=>{
  const { currentJobs } = this.state
  let tempJobs = [...currentJobs]
  let jobsEast = []
  for(let i = 0; i < tempJobs.length; i++){
      if(tempJobs[i].zipFrom.charAt(0) === "3" || tempJobs[i].zipFrom.charAt(0) === "2" || tempJobs[i].zipFrom.charAt(0) === "1" || tempJobs[i].zipFrom.charAt(0) === "0" ){
        jobsEast.push(tempJobs[i])
      }
  }
  this.setState({filteredJobs:jobsEast})
}

handleJobWest = ()=>{
  const { currentJobs } = this.state
  let tempJobs = [...currentJobs]
  let jobsWest = []
  for(let i = 0; i < tempJobs.length; i++){
      if(tempJobs[i].zipFrom.charAt(0) === "4" || tempJobs[i].zipFrom.charAt(0) === "5" || tempJobs[i].zipFrom.charAt(0) === "6" ){
        jobsWest.push(tempJobs[i])
      }
  }
  this.setState({filteredJobs:jobsWest})
}


    render() {

        return (
            <ProductContext.Provider 
            value={{
                ...this.state,
                handleClickedJob: this.handleClickedJob,
                handleSearchFrom:this.handleSearchFrom,
                handleSearchTo:this.handleSearchTo,
                handleOpenMessages:this.handleOpenMessages,
                getAllCompanies:this.getAllCompanies,
                handleSearchCompanyNameInput:this.handleSearchCompanyNameInput,
                handleSearchCompanyLocationInput:this.handleSearchCompanyLocationInput,
                handleSearchCompanyZipInput:this.handleSearchCompanyZipInput,
                handleResetCompanySearche:this.handleResetCompanySearche,
                handleResetJobSearche:this.handleResetJobSearche,
                handleNord:this.handleNord,
                handleMid:this.handleMid,
                handleEast:this.handleEast,
                handleWest:this.handleWest,
                handleOpenMap:this.handleOpenMap,
                handleJobNord:this.handleJobNord,
                handleJobMid:this.handleJobMid,
                handleJobEast:this.handleJobEast,
                handleJobWest:this.handleJobWest


            }}
            >
                {this.props.children} 
            </ProductContext.Provider>
        );
    }
}

const ProductConsumer = ProductContext.Consumer;
export { ProductProvider, ProductConsumer };