import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";
import {editUserDetails} from "../redux/actions/userActions";
import { Modal, Button } from "react-bootstrap";


class EditDetails extends Component {
    state={
        bio: '',
        open:false
    }
    mapUserDetailsToState = (credentials) => {
        this.setState({
        bio:credentials.bio ? credentials.bio : '',
    });
}

     handleOpen =()=>{
        this.setState({open:true})
        this.mapUserDetailsToState(this.props.credentials);
    }
    handleClose = ()=>{
        this.setState({open:false})
    }
    componentDidMount(){
        const {credentials} = this.props;
        this.mapUserDetailsToState(credentials);
    }


    handleChange = (event) =>{
        this.setState({
            [event.target.name]:event.target.value
        })
    }

    handleSubmit = () => {
        const userDetails = {
            bio:this.state.bio
        };
        this.props.editUserDetails(userDetails);
        this.handleClose();
    }

  
    render() {

        return (
            <StyledDiv>

            <Fragment>
            

<button type="button" className="btn btn-primary" onClick={this.handleOpen}>
 Endre profil
</button>

<Modal className="modalOverlay d-flex align-items-center justify-content-center" show={this.state.open} >

<Modal.Header closeButton onClick={this.handleClose}>
            <Modal.Title> Endre profil</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                    <div className="form-group">
                    <label htmlFor="Endre profil">Om deg</label>
                    <textarea 
                    name="bio" 
                    cols="40" 
                    rows="5"
                    type="text" 
                    className="form-control" 
                    placeholder="Jeg liker å frakte ting"  
                    multiline
                    value={this.state.bio} 
                    onChange={this.handleChange}/>
                    </div>

          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
              Lukk
          </Button>
          <Button vatiant="success" onClick={this.handleSubmit}>
              Lagre
            </Button>
          </Modal.Footer>

</Modal>

            </Fragment>            
            </StyledDiv>

        )
    }
}


const mapStateToProps = (state) =>({
    credentials:state.user.credentials
})
export default connect(mapStateToProps, {editUserDetails}) (EditDetails);


const StyledDiv = styled.div`
margin-top:30px;
margin-bottom: 20px;
 .modalOverlay{
position: fixed;
right:0;
bottom:0;
background-color: rgba(0, 0, 0, 0.6);
width: 100%;
height: 100%;
z-index: 1200;
  }


  .container{
   
  }




`
