import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect  } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import jwtDecode from "jwt-decode";
import AuthRoute from "./util/AuthRoute";
import AuthRouteNot from './util/AuthRouteNot';

//redux shit
import {Provider} from "react-redux";
import store from "./redux/store";
import {SET_AUTHENTICATED } from "./redux/types";
import { logoutUser, getUserData } from './redux/actions/userActions';
import axios from 'axios';

import Navbarnew from "./components/Navbarnew";



import home from "./pages/home";
import login from "./pages/login";
import signup from "./pages/signup";
import profile from "./pages/profile";
import user from "./pages/user";
import SingleJob from './components/SingleJob';
import om from "./pages/om";
import messages from "./pages/messages";
import AllMessages from "./components/AllMessages";
import transporter from "./pages/transporter";
import registerCompanyPage from "./pages/registerCompanyPage";
import SingleCompany from "./pages/SingleCompany";


axios.defaults.baseURL = `https://europe-west1-minfrakt.cloudfunctions.net/api`;
  
const token = localStorage.FBIdToken;
if(token){
  const decodedToken = jwtDecode(token);
  if(decodedToken.exp * 1000 < Date.now()){
    store.dispatch(logoutUser())
    window.location.href = '/login'
  } else{
    store.dispatch({type:SET_AUTHENTICATED});
    axios.defaults.headers.common['Authorization'] = token;
    store.dispatch(getUserData())
  }
}

 class App extends Component {
  render() {
    return (
      <Provider store={store}>
      <div className="app">
      <AllMessages/>
      <Router basename="/frakteting">       
       <Navbarnew/>
      <div className="">
          <Switch>
            <Route exact  path="/" component={home}/>
            <AuthRoute exact  path="/login" component={login}  />
            <AuthRoute exact  path="/signup" component={signup} />
            <Route exact path="/transporter" component={transporter}/>
            <Route exact  path="/profile" component={profile}/>
            <Route exact path="/Job:jobId" component={SingleJob} />
            <Route exact path="/users/:email" component={user}/>
            <Route exact path="/om" component={om} />
            <Route exact path="/messages/:email" component={messages} />
            <Route path="/frakter/:companyId" component={ SingleCompany }/>

            <AuthRouteNot exact path="/registerCompany" component={registerCompanyPage} />



            
            <Redirect from="*" to="/" />
        </Switch>
      </div>
      </Router>
      </div>
      </Provider>
    )
  }
}

export default App

