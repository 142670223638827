import React, { Component } from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import {uploadCompanyLogo, uploadCompanyCover } from "../redux/actions/dataActions";
import DeleteCompany from '../components/DeleteCompany';
import { Link } from "react-router-dom"


//import JobDialog from "./JobDialog";
//import Comments from "./Comments";

class companies extends Component {


  handleImageChange = (event, companyId) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append('image', image, image.name);
    this.props.uploadCompanyLogo(formData, companyId);
};

handleCoverChange = (event, companyId) => {
  const image = event.target.files[0];
  const formData = new FormData();
  formData.append('image', image, image.name);
  this.props.uploadCompanyCover(formData, companyId);
};
  
handleEditPicture = () =>{
  const fileInput = document.getElementById('imageInput');
  fileInput.click();
}

handleEditlogo = () =>{
  const fileInput = document.getElementById('imageLogo');
  fileInput.click();
}


  render() {

    const {  company : {
    companyId,
    companyCover,
    companyLogo,
    companyName,
    companyAbout,
    companyLocation,
    fraktFra1,
    fraktTil1,    
    fraktFra2,
    fraktTil2,
    companyWww,
    companyTlf,
    userFirstName,
    companyEmail,
    userEmail,
    companyZip

},
user: {
    authenticated,
    credentials: {email, }
},

} = this.props;


    const changeCompanyLogo = authenticated && email === userEmail ? (
      <>
                     <input type="file" id="imageLogo" onChange={(event)=>this.handleImageChange(event,companyId)} hidden="hidden" />
                     <FontAwesomeIcon icon={faCamera} onClick={this.handleEditlogo} className="iconButton" data-bs-toggle="tooltip" data-bs-placement="left" title="Bytt/legg til logo" />
     </>
  ) : (null)



  const changeCompanyCover = authenticated && email === userEmail ? (
    <>
                   <input type="file" id="imageInput" onChange={(event)=>this.handleCoverChange(event,companyId)} hidden="hidden" />
                   <FontAwesomeIcon icon={faCamera} onClick={this.handleEditPicture} className="iconButtonCover" data-bs-toggle="tooltip" data-bs-placement="left" title="Bytt/legg til cover bilde" />
   </>
) : (null)



const deleteButton = authenticated && email === userEmail ? (
  <DeleteCompany companyId={companyId}/>
) 
: 
null

    return (
      <StyledDiv>
  
          <div className="w-100 mainWrapper">
          <div className="card my-3">
          {deleteButton}

          {changeCompanyCover}
        <img className="card-img imageCover" src={companyCover} alt="coverimage"/>
        <div className="card-img-overlay">
          <img className="imageLogo" src={companyLogo} alt="Logo"/>
          {changeCompanyLogo}
        </div>
        <div className="card-body">
        <div className="row">
            <div className='col'>
                <Link to={`/frakter/${companyId}`}>  <h4 className="card-title" >{companyName}</h4></Link>
                 <p className="card-text">{companyAbout}</p>

            </div>
            <div className='col'>
                <h5>Faste ruter</h5>
                <div className='d-flex'>
                    <span>{fraktFra1}</span>
                    <span className="arrowRight"> <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-arrow-left-right mx-3 arrows" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/></svg></span>
                    <span>{fraktTil1}</span>
                </div>
                <div className='d-flex'>
                    <span>{fraktFra2}</span>
                    <span className="arrowRight"> <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-arrow-left-right mx-3 arrows" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/></svg></span>
                    <span>{fraktTil2}</span>
                </div>

            </div>
        </div>

        </div>
        <div className="card-footer text-muted d-flex flex-wrap justify-content-between bg-transparent border-top-0">

        <div className=""><a href={`https://${companyWww}`} target="_blank" rel="noopener noreferrer">{companyWww}</a></div>
        <div className="">{companyTlf}</div>
        <div className=""> {companyLocation}</div>
        <div className=""> {companyZip}</div>
        <div className=''>Epost: {companyEmail}</div>

           
        </div>
      </div>
      </div>
        
      </StyledDiv>

    )
  }
}

const StyledDiv = styled.div`

.card-img {
    border-radius:10px;
    height:20vh;
    width:auto;
    object-fit:  contain;

  }

  .imageLogo{
      position:absolute;
      width: 112px;
      height:112px;
      left:0;
      bottom:0;
      object-fit:  contain;

  }

.card-img-overlay{
    position:relative ;
}

.imageCover{
object-fit:  cover;
width:100%;
height:150px;
}

.arrows{
    border:1px solid black;
    border-radius:50%;
    padding:3px;
}

.iconButton{
    position: absolute;
    left:2%;
    bottom:9%;
    color:lightgray;
    filter: drop-shadow(0px 0px 5px black);
    cursor: pointer;

}

.iconButtonCover{
  position: absolute;
    left:1%;
    top:6%;
    color:lightgray;
    filter: drop-shadow(0px 0px 5px black);
    cursor: pointer;
}

`


const mapStateToProps = state =>({
  user:state.user,
  data:state.data
})

const mapActionsToProps = {
  uploadCompanyLogo,
  uploadCompanyCover
}

export default connect(mapStateToProps, mapActionsToProps)(companies);